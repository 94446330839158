import React from 'react';
import img1 from '../../assets/img/home3/about-3.jpg';
import img3 from '../../assets/img/home3/about-4.jpg';
import img2 from '../../assets/img/home3/about-5.jpg';
import ProcessContent from './ProcessContent';
import ProcessGrid2 from './ProcessGrid2';

function Process2() {
    return (
        <section className="promo-featured-wrapper section-padding">
            <div className="container">
                <div className="row align-center">
                    <div className="col-xl-5 col-12 text-center">
                        <ProcessGrid2 img1={img1} img2={img2} img3={img3} />
                    </div>
                    <div className="col-xl-7 col-12 mt-5 mt-xl-0">
                        <div className="block-contents ml-xl-5 ml-50">
                            <div className="section-title-3">
                                <p className="pl-50 pr-50">Company Insights</p>
                                <h1>Our Company Work Process.</h1>
                            </div>
                            <ProcessContent
                                heading=" Conzarc, with the vision of creating a one-stop solution, has an experienced and well-equipped team of
                                professionals. "
                                text=" We give an amalgamation of services catering to your consultation to construction
                                requirements. Our relations with the clients don&#39;t end with mere construction, but the icing on the cake
                                is the maintenance that we provide post-construction with utmost efficiency."
                            />
                            <a href="/services" className="theme-btn theme-3">
                                Learn More
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Process2;
