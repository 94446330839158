import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addToCart, removeFromCart } from "../actions/cartActions";
import { useHistory, useParams, useLocation } from "react-router-dom";
import Navstuff from "../components/Navstuff";
import { GrSubtract } from "react-icons/gr";
import { AiOutlinePlus } from "react-icons/ai";
import PageBanner from "../../components/PageBanner";
export default function Cartpage(props) {
  const [cookie, setCookie] = useState(true);
  let { id } = useParams();
  let { search } = useLocation();
  const shopID = id;
  const productID = id ? String(search.split("=")[1]) : 1;

  const dispatch = useDispatch();
  const history = useHistory();

  const cart = useSelector((state) => state.cart);
  const { cartItems } = cart;

  const checkoutHandler = () => {
    history.push("/user/signin?redirect=shipping");
  };

  const removeFromCartHandler = (productID) => {
    dispatch(removeFromCart(productID));
  };

  useEffect(() => {
    console.log(search);
    if (productID) {
      dispatch(addToCart(shopID, productID, 1));
    }

    if (navigator.cookieEnabled) {
      setCookie(true);
    } else {
      setCookie(false);
    }

    return () => {
      //
    };
  }, []);

  return (
    <div>
      <Navstuff />
      <PageBanner
        bannerBg="https://images.unsplash.com/photo-1589939705384-5185137a7f0f?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80"
        heading="Cart"
        currentPage="Cart"
      />
      <div className="">
        {cartItems.length > 0 ? (
          //CartProductsComponent
          <center>
            <br />
            <table className="product-table" width="90%">
              <tr>
                <th className="product-table-cart">PRODUCT</th>
                <th className="product-table-cart">NAME</th>
                <th className="product-table-cart">DESCRIPTION</th>
                <th className="product-table-cart">PRICE</th>
                <th className="product-table-cart">QUANTITY</th>
                <th className="product-table-cart">ACTION</th>
              </tr>
              <tbody>
                {cartItems.map((product) => (
                  <tr>
                    <td className="product-table-cart">
                      <img
                        alt={product.pname}
                        style={{ margin: "0 auto", maxHeight: "100px" }}
                        src={product.image}
                        className="img-fluid d-block"
                      />
                    </td>
                    <td className="product-table-cart">{product.pname}</td>
                    <td className="product-table-cart">{product.pdesc}</td>
                    <td className="product-table-cart">₹ {product.pprice}</td>
                    <td className="product-table-cart">{product.qty}</td>
                    <td className="product-table-cart">
                      <div>
                        <AiOutlinePlus
                          className="edit-product-icon"
                          onClick={() => {
                            dispatch(
                              addToCart(
                                product.shop_id,
                                product.product,
                                product.qty + 1
                              )
                            );
                          }}
                        />

                        {product.qty > 1 && (
                          <GrSubtract
                            onClick={() => {
                              dispatch(
                                addToCart(
                                  product.shop_id,
                                  product.product,
                                  product.qty - 1
                                )
                              );
                            }}
                            className="delete-product-icon"
                          />
                        )}

                        {product.qty === 1 && (
                          <GrSubtract
                            onClick={() =>
                              removeFromCartHandler(product.product)
                            }
                            className="delete-product-icon"
                          />
                        )}
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </center>
        ) : (
          <div className="p-3 text-center text-muted">
            {cookie ? (
              <div>Your cart is empty</div>
            ) : (
              <div>You need to allow Cookies to use the Cart</div>
            )}
          </div>
        )}
      </div>
      {cartItems.length > 0 && (
        <center>
          <div className="card-margin">
            {/* <div className="cart-card">
              <div className="mb-1">Total Items</div>
              <div className="space-cart"></div>
              <div className="mb-1">
                {cartItems.reduce((total, product) => total + product.qty, 0)}
              </div>
              </div> */}
            <div className="cart-card">
              <div className="mb-1">Total Amount</div>
              <div className="space-cart"></div>
              <div className="mb-1">
                ₹
                {cartItems.reduce(
                  (total, product) => total + product.pprice * product.qty,
                  0
                )}
              </div>
            </div>
            <div>
              <div className="text-center">
                <button
                  type="button"
                  className="shop-search-button-red"
                  onClick={checkoutHandler}
                >
                  PROCEED TO CHECKOUT
                </button>
              </div>
            </div>
          </div>
        </center>
      )}
    </div>
  );
}
