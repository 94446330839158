export const SHOP_LIST_REQUEST = 'SHOP_LIST_REQUEST';
export const SHOP_LIST_SUCCESS = 'SHOP_LIST_SUCCESS';
export const SHOP_LIST_FAIL = 'SHOP_LIST_FAIL';

export const PRODUCT_LIST_REQUEST = 'PRODUCT_LIST_REQUEST';
export const PRODUCT_LIST_SUCCESS = 'PRODUCT_LIST_SUCCESS';
export const PRODUCT_LIST_FAIL = 'PRODUCT_LIST_FAIL';

export const SHOP_SAVE_REQUEST = 'SHOP_SAVE_REQUEST';
export const SHOP_SAVE_SUCCESS = 'SHOP_SAVE_SUCCESS';
export const SHOP_SAVE_FAIL = 'SHOP_SAVE_FAIL';

export const PRODUCT_SAVE_REQUEST = 'PRODUCT_SAVE_REQUEST';
export const PRODUCT_SAVE_SUCCESS = 'PRODUCT_SAVE_SUCCESS';
export const PRODUCT_SAVE_FAIL = 'PRODUCT_SAVE_FAIL';

export const PRODUCT_DELETE_REQUEST = 'PRODUCT_DELETE_REQUEST';
export const PRODUCT_DELETE_SUCCESS = 'PRODUCT_DELETE_SUCCESS';
export const PRODUCT_DELETE_FAIL = 'PRODUCT_DELETE_FAIL';