import React from 'react';

function WidServices() {
    return (
        <div className="single-footer-wid">
            <div className="wid-title">
                <h4>Social Media</h4>
            </div>
            <ul>
                <li>
                    <a href="https://twitter.com/conzarc">Twitter</a>
                </li>
                <li>
                    <a href="https://www.facebook.com/conzarccompany">Facebook</a>
                </li>
                <li>
                    <a href="https://www.instagram.com/conzarc/?hl=en">Instagram</a>
                </li>
                <li>
                    <a href="https://www.youtube.com/channel/UCTHerNb8e3RykV2BKBaCulg">Youtube</a>
                </li>
            </ul>
        </div>
    );
}

export default WidServices;
