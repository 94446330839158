import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { listMyOrders } from "../actions/orderActions";
import { useParams } from "react-router-dom";

export default function OrderManagement(params) {
  const dispatch = useDispatch();
  let { id } = useParams();
  const myOrderList = useSelector((state) => state.myOrderList);
  const { loading: loadingOrders, orders, error: errorOrders } = myOrderList;
  const sellerSignin = useSelector((state) => state.sellerSignin);
  const { sellerInfo } = sellerSignin;

  useEffect(() => {
    dispatch(listMyOrders(id));

    return () => {};
  }, []);

  const contained = (item) => {
  
    for (let index = 0; index < item.length; index++) {
      if (item[index].shop_id === id) {
        return true;
      }
    }
    return false;
  };

  return (
    <div>
      {loadingOrders ? (
        <div> loading ... </div>
      ) : errorOrders ? (
        <div>error </div>
      ) : (
        <div>
          {orders.map((order) =>
            contained(order.cartItems) ? (
              <div>
                <h1> {order.user}</h1>
                <h5>
                  {order.shipping.address1},{order.shipping.address2},{" "}
                  {order.shipping.area}, {order.shipping.city}
                </h5>
                {order.cartItems.map((item) =>
                  item.shop_id === id ? (
                    <div>
                      {item.pname} -{item.pqty} quantity
                    </div>
                  ) : (
                    <div> </div>
                  )
                )}
              </div>
            ) : (
              <div> </div>
            )
          )}
        </div>
      )}
    </div>
  );
}
