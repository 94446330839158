/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from "react";
import FormInput from "./FormInput";
import Axios from "axios";

function ContactForm({ title, heading }) {
  // STATES
  const [message, setMessage] = useState("");
  const [userName, setUserName] = useState("");
  const [email, setEmail] = useState("");
  const [location, setLocation] = useState("");
  const [phonenumber, setPhoneNumber] = useState(0);

  // HANDLER
  const onChangeHandler = (e) => {
    setMessage(e.target.value);
  };

  const SubmitForm = async () => {
    const URL = "https://conzarc.herokuapp.com";
    console.log({
        name: userName,
        email: email,
        phonenumber: phonenumber,
        location: location,
        request: message,
      });
    const response = await Axios.post(`${URL}/api/users/request/service`, {
      name: userName,
      email: email,
      phonenumber: phonenumber,
      location: location,
      request: message,
    });
    console.log(response.status);
   
    if (response.status === 200) {
      setEmail("");
      setPhoneNumber(0);
      setUserName("");
      setLocation("");
      setMessage("");
      alert("Request has been submited");
    } else {
      alert(response.data);
    }
  };

  return (
    <section className="contact-form-wrapper section-padding pt-0">
      <div className="container">
        <div className="row">
          <div className="col-12 text-center mb-20">
            <span>{title}</span>
            <h1>{heading}</h1>
          </div>

          <div className="col-12 col-lg-12">
            <div className="contact-form">
              <form action="" className="row conact-form">
                <FormInput
                  type="text"
                  labelFor="fullname"
                  label="Full Name"
                  placeholder="Enter Name"
                  id="fullname"
                  input={userName}
                  setInput={setUserName}
                />
                <FormInput
                  type="email"
                  labelFor="email"
                  label="Email Address"
                  placeholder="Enter Email Address"
                  id="email"
                  input={email}
                  setInput={setEmail}
                />
                <FormInput
                   type="number"
                   labelFor="phone"
                   label="Phone Number"
                   placeholder="Enter Number"
                   id="phone"
                   input={phonenumber}
                   setInput={setPhoneNumber}
                />

                <FormInput
                  type="text"
                  labelFor="subject"
                  label="Location"
                  placeholder="Enter Location"
                  id="subject"
                  input={location}
                  setInput={setLocation}
                />

                <div className="col-md-12 col-12">
                  <div className="single-personal-info">
                    <label htmlFor="subject">Service Requirement</label>
                    <textarea
                      value={message}
                      onChange={onChangeHandler}
                      id="subject"
                      placeholder="Enter Service Requirement and Best time to Call"
                    />
                  </div>
                </div>

                <div className="col-md-12 col-12 text-center">
                  <button
                    className="submit-btn"
                    type="submit"
                    value="Get A Quote"
                    onClick={(e) => {
                      e.preventDefault();
                      SubmitForm();
                    }}
                  >
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default ContactForm;
