import React from "react";
import { FaFacebookF, FaInstagram, FaTwitter, FaYoutube } from "react-icons/fa";
import { Link } from "react-router-dom";
import iconCall from "../../assets/img/icon/call-icon.png";
import iconMap from "../../assets/img/icon/map-icon.png";
import logo from "../../assets/img/logo-3.png";
import WidExplore from "./WidExplore";
import WidQuestions from "./WidQuestions";
import WidServices from "./WidServices";

function Footer3() {
  return (
    <footer className="footer-3 footer-wrap">
      <div className="footer-widgets">
        <div className="footer-main-container">
          <div className="row">
            <div className="col  mx-md-5 explore-leftside">
              <WidExplore />
            </div>
            <div className="col px-md-5 mx-md-5">
              <WidServices />
            </div>
            <div className="col px-md-5 mx-md-5">
              <div className="single-footer-wid ml-15 contact_widget_2">
                <div className="wid-title">
                  <h4>Have any Question?</h4>
                </div>
                <div className="contact-us">
                  <WidQuestions
                    icon={iconCall}
                    title="Phone"
                    text="7087835461"
                  />
                  <WidQuestions
                    icon={iconMap}
                    title="Address"
                    text="Shivalik Avenue,1-B,Rampur Sahni, Sector-2,Naya nangal,Distt Ropar- Punjab 140126"
                  />
                  <WidQuestions
                    icon={iconCall}
                    title="Email"
                    text="info@conzarc.com admin@conzarc.com"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <div className="footer-bottom">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-4 col-12 order-2 order-md-1">
              <div className="copyright-info mt-3 mt-md-0"></div>
            </div>
            <div className="col-md-4 col-12 text-center order-1 order-md-2">
              <div className="footer-logo">
              <Link to="/">
                <img src={logo} alt="Conzarc" className="footer-logo" />
              </Link>
              </div>
            </div>
            <div className="col-md-4 col-12 text-md-end order-3 order-md-3">
              <div className="social_link  mt-3 mt-md-0">
                <a href="https://www.facebook.com/conzarccompany">
                  <FaFacebookF />
                </a>
                <a href="https://twitter.com/conzarc">
                  <FaTwitter />
                </a>
                <a href="https://www.instagram.com/conzarc/?hl=en">
                  <FaInstagram />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div> */}
    </footer>
  );
}

export default Footer3;
