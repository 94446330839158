import React from 'react';
import HomeMaintenanceDetailsCarousel from './DetailsCarousel';
import HomeMaintenanceDetailsContent from './DetailsContent';

function HomeMaintenanceDetails() {
    return (
        <div className="service-details-content-wrapper pl-0 pl-md-4">
            <HomeMaintenanceDetailsCarousel />
            <HomeMaintenanceDetailsContent />
            <div className="faq-content pl-0 pl-md-4">
                <h2>How do we do home Care:-</h2>
                <p>Tell us about your needs</p>
                <div className="service-details-plumbing">Determine the best time to service</div>
                <div className="service-details-plumbing">Multitalented team provide you best solution</div>
                <div className="service-details-plumbing">Sit back and relax</div>
                <div className="service-details-plumbing">Promise for a Competitive Service</div>
            </div>
        </div>
    );
}

export default HomeMaintenanceDetails;
