import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import Navstuff from "../components/Navstuff";

const URL = "https://conzarc.herokuapp.com";

const Wallet = () => {
  let { id } = useParams();

  const [isLoading, setisLoading] = useState(true);

  const [isError, setIsError] = useState(false);

  const [isPayout, setIsPyout] = useState(false);

  const [data, setData] = useState("");

  const [error, setError] = useState("");

  const getData = async () => {
    var response = await axios.get(`${URL}/api/seller/wallerbalance/${id}`);
    console.log(response.status);
    if (response.status === 200) {
      await checkPayoutRequest();

      setIsError(false);
      setData(response.data.wallet);
      setisLoading(false);
    } else {
      setError(response.data);
      setIsError(true);
      setisLoading(false);
    }
  };

  const checkPayoutRequest = async () => {
    var response = await axios.get(`${URL}/api/seller/ispayout/${id}`);

    if (response.status === 200 && response.data.isRequested) {
      setIsPyout(true);
    } else {
      setIsPyout(false);
    }
  };

  const PaymentRequest = async () => {
    setisLoading(true);
    var response = await axios.put(`${URL}/api/seller/payout/${id}`);

    if (response.status === 200) {
      await getData();
    } else {
      setisLoading(false);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <div>
      <Navstuff />
      
      <div className="center-wallet-container">
      {isLoading ? (
        <div> loading... </div>
      ) : isError ? (
        <div> {JSON.stringify(error)} </div>
      ) : (
        <div>
          <div> Wallet Balance : 1</div>
          {isPayout ? (
            <h4>Payment Request Already submited </h4>
          ) : (
            <div>
              <button
                onClick={(e) => {
                  e.preventDefault();
                  PaymentRequest();
                }}
              >
                Payout Request
              </button>
            </div>
          )}
        </div>
      )}
      </div>
    </div>
  );
};

export default Wallet;
