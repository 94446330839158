import React from "react";
import Loading from "./Loading";
import "./Product.css";
import { useHistory } from "react-router-dom";

export const Product = (props) => {
  const items = props.data;
  const history = useHistory();
  if (items) {
    return (
      <div>
        <div className="grid-container">
          {props.data.map((product) => {
            return (
              <div className="grid-item">
                <img src={product.image} width="200" height="200" alt="" onClick={(e) => {
                  e.preventDefault();
                  history.push("/shop/product-detail", {
                    state : {
                      prodData : product,
                      shopid:props.id
                    }
                  });
                }}/>
                <p style={{ margin: "0" }}>
                  <b>{product.pname}</b>
                </p>
                <p style={{ margin: "0" }}>
                  <b>₹ {product.pprice}</b>
                </p>
                <a href={"/cart/" + props.id + "?product=" + product._id}>
                  <div className="form__button cart-button">Add To Cart</div>
                </a>
              </div>
            );
          })}
        </div>
      </div>
    );
  } else {
    return <Loading />;
  }
};
