import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import ScrollIndicator from "./components/ScrollIndicator";
import ScrollToTopRoute from "./components/ScrollTopRoute";
import About from "./pages/About";
import Contact from "./pages/Contact";
import Faq from "./pages/Faq";
import Home3 from "./pages/Home3";
import News from "./pages/News";
import NewsDetails from "./pages/NewsDetails";
import ProjectDetails from "./pages/ProjectDetails";
import Projects from "./pages/Projects";
import Services from "./pages/Services";
import PaintingDetails from "./pages/PaintingDetails";
import Team from "./pages/Team";

import UserSigninpage from "./cart/Pages/UserSigninpage";
import UserRegisterPage from "./cart/Pages/UserRegisterpage";
import SellerSigninpage from "./cart/Pages/SellerSigninpage";
import SellerRegisterpage from "./cart/Pages/SellerRegisterpage";
import DeliveryLoginpage from "./cart/Pages/DeliveryLoginpage";
import Homepage from "./cart/Pages/Homepage";
import Shoppage from "./cart/Pages/Shoppage";
import Cartpage from "./cart/Pages/Cartpage";
import Shippingpage from "./cart/Pages/Shipping";
import Placeorderpage from "./cart/Pages/Placeorderpage";
import Sellerinventorypage from "./cart/Pages/Sellerinventorypage";
import Sellerproductspage from "./cart/Pages/Sellerproductspage";
import Userorderpage from "./cart/Pages/Userorderpage";
import OrderManagement from "./cart/Pages/OrderManagement";
import OrderInfo from "./cart/Pages/OrderInfo";
import Wallet from "./cart/Pages/wallet";
import Payment from "./cart/Pages/Payment";
import Privacy from "./pages/Privacy";
import ShippingPolicy from "./pages/ShippingPolicy";
import Terms from "./pages/Terms";
import GardenPage from "./pages/GardenPage";
import PlumbingPage from "./pages/PlumbingPage";
import QualityPolicy from "./pages/QualityPolicy";
import { HomeMaintenancePage } from "./pages/HomeMaintenancePage";
import CarpentaryPage from "./pages/Carpentary";
import ConstructionPage from "./pages/Construction";
import ConstrcutionMaterialPage from "./pages/ConstructionMaterial";
import DesignPlanningPage from "./pages/DesignPlanning";
import Complaints from "./pages/Complaints";
import SafetyPolicy from "./pages/SafetyPolicy";
import { ReturnPolicy } from "./pages/ReturnPolicy";
import ShopProductDetailPage from "./cart/Pages/ShopProductDetailPage";

function App() {
  return (
    <>
      <Router>
        <ScrollToTopRoute />
        <Switch>
          <Route exact path="/about">
            <About />
          </Route>
          <Route exact path="/team">
            <Team />
          </Route>
          <Route exact path="/faq">
            <Faq />
          </Route>
          <Route exact path="/projects">
            <Projects />
          </Route>
          <Route exact path="/services">
            <Services />
          </Route>
          <Route exact path="/painting-and-decorations">
            <PaintingDetails />
          </Route>
          <Route exact path="/cart/project-details">
            <ProjectDetails />
          </Route>
          <Route exact path="/news">
            <News />
          </Route>
          <Route exact path="/garden">
            <GardenPage></GardenPage>
          </Route>

          <Route exact path="/plumbing">
            <PlumbingPage></PlumbingPage>
          </Route>
          <Route exact path="/news-details">
            <NewsDetails />
          </Route>
          <Route exact path="/contact">
            <Contact />
          </Route>
          <Route exact path="/terms">
            <Terms />
          </Route>
          <Route exact path="/">
            <Home3 />
          </Route>
          <Route exact path="/quality">
            <QualityPolicy/>
          </Route>
          <Route exact path="/privacy">
            <Privacy />
          </Route>
          <Route exact path="/return-and-cancellation-policy">
            <ReturnPolicy />
          </Route>
          <Route exact path="/home-maintenance">
            <HomeMaintenancePage></HomeMaintenancePage>
          </Route>
          <Route exact path="/carpentary">
            <CarpentaryPage/>
            </Route>
            <Route exact path="/construction">
            <ConstructionPage/>
            </Route>
            <Route exact path="/construction-material">
            <ConstrcutionMaterialPage/>
            </Route>
            <Route exact path="/design-and-planning">
            <DesignPlanningPage/>
            </Route>
            <Route exact path="/complaints">
            <Complaints/>
            </Route>
          <Route exact path="/shipping">
            <ShippingPolicy></ShippingPolicy>
          </Route>
          <Route exact path="/safety-policy">
            <SafetyPolicy/>
          </Route>
          <Route path="/user/signin">
            <UserSigninpage />
          </Route>
          <Route path="/user/register">
            <UserRegisterPage />
          </Route>

          <Router path="/seller/signin">
            <SellerSigninpage />
          </Router>
          <Route path="/seller/register">
            <SellerRegisterpage />
          </Route>
          <Route path="/delivery/signin">
            <DeliveryLoginpage />
          </Route>
          <Route path="/deliveryorder/:id">
            <div>delivery details</div>
            {/* <OrderDeliverydetailspage /> */}
          </Route>

          <Route exact path="/shop/product-detail">
          <ShopProductDetailPage />
          </Route>

          <Route path="/shop/:id">
            <Shoppage />
          </Route>

        
          <Route path="/shop">
            <Homepage />
          </Route>

          <Route path="/cart/:id?">
            <Cartpage />
          </Route>

          <Route path="/user/shipping">
            <Shippingpage />
          </Route>

          <Route path="/user/placeorder">
            <Placeorderpage />
          </Route>

          <Route path="/createshop/:id">
            <Sellerinventorypage />
          </Route>

          <Route path="/addproducts/:id">
            <Sellerproductspage />
          </Route>
          <Route path="/wallet/:id">
            <Wallet />
          </Route>
          <Route path="/userorder/:id">
            <Userorderpage />
          </Route>

          <Route path="/seller/orders/:id">
            <OrderManagement />
          </Route>
          <Route path="/seller/orderdetails/:id/:sellerid">
            <OrderInfo />
          </Route>
          <Route path="/payment">
            <Payment />
          </Route>
        </Switch>
      </Router>
      <ScrollIndicator />
    </>
  );
}

export default App;
