/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
import React from "react";
import contactData from "./contactData";
import ContactItem from "./ContactItem";
import Map from "./Map";

function ContactUs() {
  return (
    <section className="contact-page-wrap">
      <div className="container">
          <br/>
        <div>
          With a few simple clicks, we'll help you identify the perfect
          Construction services as well as Products under one roof.
        </div>
        <br/>
        <div>
          Send us a message, or call us for a quote. Use our contact form to
          tell us more about your project, and we will connect you with the
          services to fit your needs & all your queries will be answered by our
          Technical team.
        </div>
        <br/>
        <div className="row">
          {contactData.map((data) => (
            <ContactItem
              key={data.id}
              heading={data.heading}
              text={data.text}
              icon={data.icon}
              item1={data.item1}
              item2={data.item2}
            />
          ))}
        </div>

        <div className="row">
          <div className="col-12 col-lg-12">
            <div className="contact-map-wrap">
              <Map />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default ContactUs;
