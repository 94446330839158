

import React from "react";
import ReactDOM from "react-dom";
import SimpleReactLightbox from "simple-react-lightbox";
import App from "./App";
import "./index.scss";
import "./all.min.css";
import reportWebVitals from "./reportWebVitals";
import { Provider } from 'react-redux';
import store from './store';


ReactDOM.render(
  <Provider store={store}> 
    <SimpleReactLightbox>
      <App />
    </SimpleReactLightbox>
  </Provider>
  ,
  document.getElementById("root")
);

reportWebVitals();
