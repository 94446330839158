import React from "react";

const ShippingContent = () => {
  return (
    <div className="privacy-policy">
      <div>Shipping Policy</div>
      <div>
        Thanks for shopping at <a href="www.Conzarc.com">www.Conzarc.com</a>
      </div>
      <div>
        All prices listed in Conzarc are generally inclusive of shipping. If
        additional shipping charges are to be levied by the
        Supplier/Manufacturer/Authorized Dealer which requires additional heavy
        transportation services which are solely based on the size of the order,
        the same will be informed prior to shipment.
      </div>
      <h6>Shipping Charges</h6>
      <div>
        Shipping Charges is not included in product price so it may vary based
        on the distance and weight of products & doesn’t have any hidden costs
        or additional charges. The price mentioned on the website is the final,
        and all taxes are inclusive.
      </div>
      <div>Your shipment may contain the documents:</div>
      <div>
        1. Copy of the Order invoice (Original copy can be downloaded from your
        account profile and copy would be sent to your email id)
      </div>
      <div>
        2. Copy of the Delivery note (Quantities has to be checked on the time
        of delivery, any missing quantities should be raised immediately to
        (www.Conzarc.com)
      </div>
      <div>3. User manual if any provided by the manufacturer.</div>
      <div>
        4. Material Test Certificates, if any provided by the manufacturer.
      </div>
      <div>5. Installation Manuals, if any provided by the manufacturer.</div>
      <div>6. Warranty Cards, if any provided by the manufacturer.</div>
      <h6>Delivery Time</h6>
      <div>
        Conzarc shall ensure the timely delivery of the order placed on the
        marketplace, however does not guarantee the exact/timely delivery of the
        products purchased. Since, products are directly shipped from the
        Supplier/Manufacturer/Authorized Dealer and Conzarc does not hold any
        control or responsibility on shipping.
      </div>

      <div>
        Conzarc takes approximately around 7-10 days to deliver the product to
        your location after you complete the order. However in extreme cases, it
        may take longer to ship the product. In such cases you will be intimated
        well in advance.
      </div>
      <h6>Order Tracking</h6>
      <div>
        If you have purchased online while logged in, you will be able to track
        the shipment from your account dashboard, track the order status online
        on Conzarc, provided that the shipment/logistics providers have the
        ability to provide the same information.
      </div>
      <div>
        You may contact info@Conzarc.com or call us to know the status of your
        order
      </div>
      <div>
        Conzarc reserves the right to withhold/ cancel the order without
        delivery without giving any reason.
      </div>
      <div>
        Orders once processed and the material has be shipped by the
        manufacturer, cannot be returned for clarifications the customer can
        reach us on info@Conzarc.com
      </div>
      <div>Thanks for shopping</div>
      <div>Your Trust our Responsibility</div>
    </div>
  );
};

export default ShippingContent;
