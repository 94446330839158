import React from 'react';

const SellerSteps = (props) => {
  return (
    <div className='checkout-step'>
      <div className={props.step1 ? 'line-active' : 'line-inactive'}>Signup as Seller</div>
      <div className={props.step2 ? 'line-active' : 'line-inactive'}>Create your Shop </div>
      <div className={props.step3 ? 'line-active' : 'line-inactive'}>Manage Products</div>
    </div>
  );
};

export default SellerSteps;
