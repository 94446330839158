import React from 'react';
import bannerBg from '../assets/img/about-banner.jpg';
import About2 from '../components/About2';
import Approch from '../components/Approch';
import Footer3 from '../components/Footer3';
import Header3 from '../components/Header3';
import PageBanner from '../components/PageBanner';
import SkillSet from '../components/SkillSet';
import Sponsors from '../components/Sponsors';
import Portfolio1 from '../components/Portfolio1';
import TeamMembers from '../components/TeamMembers';

function About() {
    return (
        <>
            <Header3 />
            <PageBanner bannerBg={bannerBg} currentPage="About Us" heading="" />
            <About2 />
            <Approch />
            <Portfolio1></Portfolio1>
            <TeamMembers/>
            <SkillSet />
            <Sponsors />
            {/* <Timeline /> */}
            <Footer3 />
        </>
    );
}

export default About;
