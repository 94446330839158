import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { saveShipping } from "../actions/cartActions";
import CheckoutSteps from "../components/CheckoutSteps";

export default function Shippingpage(props) {
  const [address1, setAddress1] = useState("");
  const [address2, setAddress2] = useState("");
  const [city, setCity] = useState("Surat");
  const [area, setArea] = useState("");
  const [phone, setPhone] = useState("");
  const history = useHistory();
  const dispatch = useDispatch();

  const submitHandler = (e) => {
    e.preventDefault();
    dispatch(saveShipping({ address1, address2, city, area, phone }));
    history.push("placeorder");
  };

  return (
    <div style={{ alignContent: "space-between" }}>
      <CheckoutSteps step1 step2></CheckoutSteps>
      <div pad="small" responsive="true">
        <center>
          <div className="form-container">
            <form onSubmit={submitHandler}>
              <h3 className="form__title">Shipping</h3>
              <div className=" col-12">
                <label for="Address line 1">Address line 1: </label>
                <br />
                <input
                  id="outlined-required"
                  label="Address line 1"
                  className="form__input sigin-input"
                  defaultValue=""
                  onChange={(e) => setAddress1(e.target.value)}
                />
              </div>
              <div className=" col-12">
                <label for="Address line 2">Address line 2: </label>
                <br />
                <input
                  id="outlined-required"
                  label="Address line 2"
                  className="form__input sigin-input"
                  defaultValue=""
                  onChange={(e) => setAddress2(e.target.value)}
                />
              </div>
              <div className=" col-12">
                <label for="Area">Area: </label>
                <br />
                <input
                  id="outlined-required"
                  label="Area"
                  className="form__input sigin-input"
                  defaultValue=""
                  onChange={(e) => setArea(e.target.value)}
                />
              </div>
              <div className=" col-12">
                <label for="City:">City: </label>
                <br />
                <input
                  id="outlined-required"
                  label="City:"
                  className="form__input sigin-input"
                  onChange={(e) => setCity(e.target.value)}
                />
              </div>
              <div className=" col-12">
                <label for="+91 Phone Number">+91 Phone Number: </label>
                <br />
                <input
                  id="outlined-required"
                  label="+91 Phone Number"
                  defaultValue=""
                  className="form__input sigin-input"
                  onChange={(e) => setPhone(e.target.value)}
                />
              </div>
              <button type="submit" className="shop-search-button">
                Continue
              </button>
            </form>
          </div>
        </center>
      </div>
    </div>
  );
}
