import React from 'react';

function HomeFormInput({ labelFor, label, placeholder, id, type , input , setInput }) {
    // STATES
  ;
    return (
        <div className="col-md-6 col-12">
            <div className="single-personal-info">
                <label htmlFor={labelFor}>{label}</label>
                <input
                    value={input}
                    onChange={(e)=> setInput(e.target.value)}
                    type={type}
                    id={id}
                    placeholder={placeholder}
                />
            </div>
        </div>
    );
}

export default HomeFormInput;