import PageBanner from "../../components/PageBanner";
import Navstuff from "../components/Navstuff.js";

import { useLocation } from "react-router-dom";
const ShopProductDetailPage = () => {
  const location = useLocation();
  const product = location.state.state.prodData;
  const shopid = location.state.state.shopid;

  return (
    <div>
      <Navstuff />
      <PageBanner
        bannerBg="https://images.unsplash.com/photo-1589939705384-5185137a7f0f?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80"
        heading={product.pname}
        currentPage="Product"
      />
      <div className="product-detail-container">
        <div className="product-detail-image-container">
          <img
            src={product.image}
            alt={product.pname}
            className="product-detail-image"
          />
          <div className="product-detail-heading">
            <h3>{product.pname}</h3>
            <h4>₹{product.pprice}</h4>
            <div className="product-detail-description-heading">
              Description
            </div>
            <div className="product-detail-description">₹{product.pdesc}</div>
            <a href={"/cart/" + shopid + "?product=" + product._id}>
              <div className="product-detail-button">Add To Cart</div>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShopProductDetailPage;
