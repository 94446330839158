import img1 from '../../assets/img/kappa.jpg';

const aboutTwoData = {
    img: img1,
    heading: 'Incorporated in 2022, Conzarc aims to be a construction hub based in India.',
    quote: 'Capitalise on low hanging fruit to identify a ballpark value added activity to beta test with a new way in new situation.',
    author: 'Miranda H.',
    position: '- Founder',
    text: 'Built on the vast experience in the field, Conzarc was developed to provide construction-related solutions that integrated functionality, cutting-edge solutions, modern designs, and creativity with sustainable ideas.',
};

export default aboutTwoData;
