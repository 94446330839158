import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { register } from "../actions/userActions";
import Loading from "../components/Loading";

export default function UserRegisterpage(props) {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [rePassword, setRePassword] = useState("");
  const userRegister = useSelector((state) => state.userRegister);
  const { loading, userInfo, error } = userRegister;
  // const redirect = props.location.search
  //   ? props.location.search.split('=')[1]
  //   : '/';
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    if (userInfo) {
      history.replace("/shop");
    }
    return () => {
      //
    };
  }, [userInfo]);

  const submitHandler = (e) => {
    e.preventDefault();
    dispatch(register(name, email, password, rePassword));
  };

  return (
    <div>
      <div className="form_container">
        <form onSubmit={submitHandler}>
          <h1 className="form__title">Create Account</h1>
          <li>
            {loading && (
              <div>
                <div style={{ padding: "80px" }}>
                  <Loading />
                </div>
              </div>
            )}
            {error && <div>{error}</div>}
          </li>
          <center>
            <div className=" col-12">
              <div className="single-personal-info">
                <label htmlFor="name">Name</label>
                <br />
                <input
                  type="name"
                  name="name"
                  id="name"
                  className="form__input sigin-input-register"
                  onChange={(e) => setName(e.target.value)}
                ></input>
              </div>
            </div>
            <div className=" col-12">
              <div className="single-personal-info">
                <label htmlFor="email">Email</label>
                <br />
                <input
                  type="email"
                  name="email"
                  id="email"
                  className="form__input sigin-input-register"
                  onChange={(e) => setEmail(e.target.value)}
                ></input>
              </div>
            </div>
            <div className=" col-12">
              <div className="single-personal-info">
                <label htmlFor="password">Password</label>
                <br />
                <input
                  type="password"
                  id="password"
                  name="password"
                  className="form__input sigin-input-register"
                  onChange={(e) => setPassword(e.target.value)}
                ></input>
              </div>
            </div>
            <div className=" col-12">
              <div className="single-personal-info">
                <label htmlFor="rePassword">Re-Enter Password</label>
                <br />
                <input
                  type="password"
                  id="rePassword"
                  name="rePassword"
                  className="form__input sigin-input-register"
                  onChange={(e) => setRePassword(e.target.value)}
                ></input>
              </div>
            </div>

            <div style={{ textAlign: "center" }}>
              <button
                type="submit"
                className="shop-search-button"
                onClick={submitHandler}
              >
                Register
              </button>
            </div>
            <div style={{ textAlign: "center" }}>
              Already have an account? <Link to="/user/signin">Sign-in</Link>
            </div>
          </center>
        </form>
      </div>
    </div>
  );
}
