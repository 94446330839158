import React from 'react';
import { AiOutlineMail, AiOutlinePhone } from 'react-icons/ai';
import {FaMapMarkerAlt } from 'react-icons/fa';
import ServiceContact from './ServiceContact';

function ServiceSidebar() {
    return (
        <div className="service-details-sidebar">
            <div className="single-service-sidebar site_info_widget">
                <div className="sidebar-title">
                    <h3>Contact Us</h3>
                </div>
                <div className="contact-us">
                    <ServiceContact
                        title="Phone Number"
                        text="7087835461"
                        icon={<AiOutlinePhone />}
                    />
                    <ServiceContact
                        title="Email Address"
                        text="info@Conzarc.com"
                        icon={<AiOutlineMail />}
                    />
                    <ServiceContact
                        title="Office Address"
                        text="Shivalik Avenue,1-B,Rampur Sahni,Sector-2,Naya nangal,Distt Ropar- Punjab 140126"
                        icon={<FaMapMarkerAlt />}
                    />
                </div>
            </div>
        </div>
    );
}

export default ServiceSidebar;
