import React from 'react';

function Map() {
    return (
        <div id="map">
            <iframe
                title="map"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3406.1101783470685!2d76.3389918!3d31.3835254!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x391acf6b37974819%3A0xb6339dc84cc08db0!2sShivalik%20Avenue%201B!5e0!3m2!1sen!2sin!4v1651317825997!5m2!1sen!2sin"
                frameBorder="0"
                style={{
                    border: '0',
                    width: '100%',
                }}
                allowFullScreen=""
                aria-hidden="false"
            />
        </div>
    );
}

export default Map;
