import React from "react";
import Header3 from "../components/Header3";
import PageBanner from "../components/PageBanner";
import bannerBg from "../assets/img/page-banner.jpg";
import Footer3 from "../components/Footer3";
import TermsContent from "../components/TermsContent";

const Terms = () => {
  return (
    <>
      <Header3 />
      <PageBanner
        bannerBg={bannerBg}
        heading="Terms and Conditions"
        currentPage="Terms"
      />
      <TermsContent></TermsContent>
      <Footer3></Footer3>
    </>
  );
};

export default Terms;