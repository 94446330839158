import React from 'react';
import bannerBg from '../assets/img/page-banner.jpg';
import BlogDetails from '../components/BlogDetails';
import Header3 from '../components/Header3';
import PageBanner from '../components/PageBanner';

function NewsDetails() {
    return (
        <>
            <Header3 />
            <PageBanner bannerBg={bannerBg} heading="Our Work" currentPage="Our Work" />
            <BlogDetails />
        </>
    );
}

export default NewsDetails;
