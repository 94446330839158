import React from "react";
import Footer3 from "../components/Footer3";
import Header3 from "../components/Header3";
import PageBanner from "../components/PageBanner";
import bannerBg from "../assets/img/page-banner.jpg";
import HomeMaintenanceDetails from "../components/Home_Maintenance";
import ServiceSidebar from '../components/ServiceSidebar';
export const HomeMaintenancePage = () => {
  return (
    <>
      <Header3></Header3>
      <PageBanner
        bannerBg={bannerBg}
        heading="Home Maintenance"
        currentPage="Home Maintenance"
      />
            <section className="service-details-wrapper section-padding">
        <div className="container">
          <div className="row">
            <div className="col-lg-3 col-md-4 mt-5 mt-md-0 col-12 order-2 order-md-1">
              <ServiceSidebar />
            </div>
            <div className="col-lg-9 col-md-8 col-12 order-1 order-md-2">
            <HomeMaintenanceDetails/>
            </div>
          </div>
        </div>
      </section>
      
      <Footer3/>
    </>
  );
};
