import React from "react";
import DesignPlanningCarousel from "./DetailsCarousel";
import DesignPlanningContent from "./DetailsContent";

function DesignPlanningDetails() {
  return (
    <div className="service-details-content-wrapper pl-0 pl-md-4">
      <DesignPlanningCarousel />
      <DesignPlanningContent />
      <div className="faq-content pl-0 pl-md-4">
        <h2>Architectural and Engineering Specifications</h2>
        <div className="service-details-plumbing">
          Construction Engineering (Building Planning and Design)
        </div>
        <div className="service-details-plumbing">
          Design conception (foundations, load bearing and enclosing structures)
        </div>
        <div className="service-details-plumbing">
          Internal engineering systems: heating, ventilation and air
          conditioning, heat supply, cold supply
        </div>
        <div className="service-details-plumbing">
          Water supply and sewerage system
        </div>
        <div className="service-details-plumbing">
          Power supply and electric lighting
        </div>
        <div className="service-details-plumbing">
          fire extinguishing systems (alert systems, fire alarms, automatic
          firefighting equipment, water, gas and powder fire fighting systems,
          gravity and forced smoke evacuation systems)
        </div>
        <div className="service-details-plumbing">
          Automation of utility systems and production controls
        </div>
        <div className="service-details-plumbing">
          Information Systems: Data Processing Centers (DCPs), Structured
          Cables, and Communication Systems; Estimates
        </div>
        <div>
          Our experienced architects can work within your budget to thoroughly
          analyze your needs and the potential opportunities for your home in
          order to develop a cost-effective solution that meets the needs of
          your family.
        </div>
      </div>
    </div>
  );
}

export default DesignPlanningDetails;
