import React, { useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { saveShop, listShops } from "../actions/shopActions";
import SellerSteps from "../components/SellerSteps.js";
import Loading from "../components/Loading";

export default function Sellerinventorypage(props) {
  let { id } = useParams();
  console.log("uid", id);

  let sellerid = id;
  const [name, setName] = useState("");
  const [desc, setDesc] = useState("");
  const [address1, setAddress1] = useState("");
  const [address2, setAddress2] = useState("");
  const [area, setArea] = useState("");
  const [category, setCategory] = useState("");
  const [image, setImage] = useState("");

  const UploadFileHandler = (e) => {
    const file = e.target.files[0];
    setImage(file);
  };
  const shopSave = useSelector((state) => state.shopSave);
  const {
    loading: loadingSave,
    success: successSave,
    error: errorSave,
  } = shopSave;

  //This is to fetch all the shops
  const shopList = useSelector((state) => state.shopList);
  const { shops, loading } = shopList;
  const history = useHistory();
  let object;
  if (!loading) {
    let selleridstring = "" + sellerid;
    object = shops.find((x) => x.seller_id === selleridstring);
  }
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(listShops());
    if (successSave) {
      history.push("/addproducts/" + id);
    }
    //to check if shop already exsist if so redirect
    if (object) {
      if (object._id) {
        console.log(object._id);
        history.push("/addproducts/" + id);
      }
    }
    return () => {
      //
    };
  }, [successSave, object]);

  const submitHandler = (e) => {
    e.preventDefault();
    let formData = new FormData();
    formData.append("name", name);
    formData.append("description", desc);
    formData.append("address1", address1);
    formData.append("address2", address2);
    formData.append("area", area);
    formData.append("image", image);
    formData.append("category", category);
    dispatch(saveShop(formData, id));
  };

  return (
    <div>
      <SellerSteps step1 step2 />
      <div>
        <div className="form-container">
          <form onSubmit={submitHandler}>
            <center>
              <h3 className="form__title">Create your Online Shop</h3>
            </center>
            <li>
              {loadingSave && (
                <div>
                  {/* Loading */}
                  <div style={{ padding: "80px" }}>
                    <Loading />
                  </div>
                </div>
              )}
              {errorSave && <div>{errorSave}</div>}
            </li>

            <center>
              <div className=" col-12">
                <div className="single-personal-info">
                  <label htmlFor="name">Shop Name</label>
                  <br />
                  <input
                    type="text"
                    className="form__input sigin-input"
                    id="name"
                    name="name"
                    onChange={(e) => setName(e.target.value)}
                  />
                </div>
              </div>
              <div className=" col-12">
                <div className="single-personal-info">
                  <label htmlFor="name">Shop Description</label>
                  <br />
                  <input
                    name="description"
                    className="form__input sigin-input"
                    value={desc}
                    id="desc"
                    onChange={(e) => setDesc(e.target.value)}
                  />
                </div>
              </div>
              <div className=" col-12">
                <div className="single-personal-info">
                  <label htmlFor="name">Shop Address(Shop and Street)</label>
                  <br />
                  <div direction="column" gap="small">
                    <input
                      name="address1"
                      className="form__input sigin-input"
                      value={address1}
                      id="address1"
                      onChange={(e) => setAddress1(e.target.value)}
                    />
                    <br />
                    <label htmlFor="name">Shop Address(Area)</label>
                    <br />
                    <input
                      name="address2"
                      className="form__input sigin-input"
                      value={address2}
                      id="address2"
                      onChange={(e) => setAddress2(e.target.value)}
                    />
                    <br />
                    <label htmlFor="name">Shop Address(City)</label>
                    <br />
                    <input
                      name="area"
                      className="form__input sigin-input"
                      value={area}
                      id="area"
                      onChange={(e) => setArea(e.target.value)}
                    />
                  </div>
                </div>
              </div>
              <div className=" col-12">
                <div className="single-personal-info">
                  <label htmlFor="name">Shop Image</label>
                  <br />
                  <input
                    accept="image/*"
                    onChange={UploadFileHandler}
                    id="icon-button-file"
                    type="file"
                  />
                </div>
              </div>
              <div className=" col-12">
                <div className="single-personal-info">
                  <label htmlFor="name">Category</label>
                  <br />
                  <input
                    type="text"
                    name="category"
                    className="form__input sigin-input"
                    value={category}
                    id="category"
                    onChange={(e) => setCategory(e.target.value)}
                  />
                </div>
              </div>
              <br />
              <button type="submit" className="shop-search-button">
                Create Shop
              </button>
              <br />
              <br />
            </center>
          </form>
        </div>
      </div>
    </div>
  );
}
