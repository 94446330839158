import React from "react";
import { SRLWrapper } from "simple-react-lightbox";
import img1 from "../../assets/img/gallery/5.jpg";
import img2 from "../../assets/img/gallery/6.jpg";

function PlumbingDetailsContent() {
  return (
    <>
      <p>
        Call us or book a local certified technician for repairs, if you have
        higher expectations with your Plumbers but have not found the
        appropriate one.
      </p>
      <h2>SERVICE DESCRIPTION</h2>
      <p>
        Our highly qualified plumbers have years of experience working in both
        residential and commercial properties. To make plumber more efficient,
        we try to make plumber more expert through various challenges and tasks
        through a project so that we can meet the requirements of the customer.
      </p>
      <h2>Residential plumbing services</h2>
      <p>
        <div>
          We pride ourselves in providing technical, cost-effective and
          efficient plumbing service.
        </div>
        <br />
        <div>
          Whether it is customer refurbishment or repair, we take our customer's
          expectations seriously and meet them; we provide technical services by
          acquiring technical expertise to complete plumbing services projects
          efficiently.
        </div>
        <br />
        <div>
          Our team of professionals fully trained to the highest standards, we
          guarantee great work using only the latest equipment and the best
          materials.
        </div>
      </p>
      <h2>Commercial plumbing services</h2>
      <p>
        Our trained team is always ready to solve your business plumbing
        problems, taking any kind of problem seriously in the business field; we
        solve your problems promptly by efficient.
      </p>
      <h2>Emergency plumbing repair</h2>
      <p>
        <div>
          When plumbing problems suddenly come knocking, serious problems arise,
        </div>
        <br />
        <div>
          In this critical situation we are unable to find skilled craftsmen due
          to which your problems become worse. We are available 24*7 for
          emergency repairs to solve your problem. Experienced in handling your
          problem quickly, effectively and ready to help you at all times
        </div>
        <br/>
        <div>If your tap is licked then do not wait for booking of plumber</div>
        <br/>
        <div>Notify us immediately as we want to solve your water wastage and your problem immediately</div>
      </p>
      <SRLWrapper>
        <div className="row">
          <div className="col-md-6 col-12">
            <a href={img1} className="popup-gallery">
              <img src={img1} alt="" />
            </a>
          </div>
          <div className="col-md-6 col-12">
            <a href={img2} className="popup-gallery">
              <img src={img2} alt="" />
            </a>
          </div>
        </div>
      </SRLWrapper>
    </>
  );
}

export default PlumbingDetailsContent;
