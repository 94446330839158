import React from "react";

const ComplaintsContent = () => {
  return (
    <div className="privacy-policy">
      <div>
        At Conzarc we are committed to providing high quality service to all our
        customers. However, if you think things have gone wrong, please send us
        your complaints and comments. We take them very seriously.
      </div>
      <div>
        We are an ISO 9001:2015 certified company. This means that we must
        follow a strict procedure for handling complaints. We have prepared the
        following guidelines to assist you. For more information, see our
        complaints policy
      </div>
      <div>How to make your complaint</div>
      <div>
        In the first instance, if you are unhappy with any part of the service
        being provided to you by Construction Line, you should contact our
        customer service team, who will be happy to assist you with your query.
      </div>
      <div>Please contact our customer service team: By phone: 07087835461</div>
      <div>
        Provide your registration number by email to admin@conzarc.com and as
        many details as possible about your concerns, as well as the way you
        would like us to respond (call back or email)
      </div>
      <div>
        In most cases, our customer service team will be able to assist you in
        resolving your query. However, if you are dissatisfied with the result,
        or feel that your complaint needs to be dealt with in a more formal
        capacity, please contact us at: by email to admin@Conzarc.com
      </div>
      <div>
        Whichever contact method you use, please provide your registration
        number, full details of your complaint, what we would like to do to
        resolve the issue, any action you have already taken to resolve the
        issue. Explain clearly by description and method. What you want us to
        answer (whether you want to call back or email).
      </div>
      <div>What happens next?</div>
      <div>
        We will notify you of receipt of your complaint and call you within two
        working days to discuss your concerns. We will investigate the issue and
        provide a full written response within 15 working days of receiving your
        complaint. If this is not possible, we will explain the reason and give
        you a date by which you can expect a complete reply.
      </div>
      <div>If you are not satisfied with our answer</div>
      <div>
        If you are not satisfied with the response to your complaint, you can
        request a review of the complaint. Please email or write to us
        explaining why you disagree with the feedback and how you would like the
        complaint to be resolved.
      </div>
      <div>If you're still not satisfied</div>
      <div>User's views</div>
      <div>
        One of the ways to continue improving your services is to listen and
        respond to your ideas.
      </div>
      <div>
        To ensure that it works as efficiently and effectively as possible, we
        will also welcome any comments or suggestions you may have on our
        complaints process.
      </div>
      <div>How to contact us</div>
      <div>
        Email: <a href="mailto: admin@conzarc.com">admin@conzarc.com</a>
      </div>
    </div>
  );
};

export default ComplaintsContent;
