import React from 'react';
import GardenDetailsCarousel from './DetailsCarousel';
import GardenDetailsContent from './DetailsContent';

function GardenDetails() {
    return (
        <div className="service-details-content-wrapper pl-0 pl-md-4">
            <GardenDetailsCarousel />
            <GardenDetailsContent />
        </div>
    );
}

export default GardenDetails;
