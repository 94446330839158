import React from "react";

const PrivacyContent = () => {
  return (
    <div className="privacy-policy">
      <center>
        <h3>Introduction about CONZARC Privacy Policy: </h3>
      </center>
      <div>
        We CONZARC are committed to protecting the privacy of our website
        visitors; in this policy we describe how we will treat your personal
        information.
      </div>
      <div>
        By using our website and agreeing to this policy, you consent to our use
        of cookies in accordance with the terms of this policy.
      </div>
      <center>
        <h3>PRIVACY POLICY</h3>
      </center>
      <center>
        <h4>Effective as of 25-03-2022</h4>
      </center>
      <div>Collecting Personal Information</div>
      <div>
        We may collect, store and use the following kinds of personal
        information:
      </div>
      <div>
        You will share certain Personal Information with CONZARC in the course
        of your use of the CONZARC Service; CONZARC respects your personal
        information. Information about your computer and about your visits to
        and use of this website (including your IP address, geographical
        location, browser type and version, operating system, referral source,
        length of visit, page views and website navigation paths);
      </div>
      <div>
        Information that you provide to us when registering with our website
        including your email address;
      </div>
      <div>
        Information that you provide to us for the purpose of subscribing to our
        email notifications and/or newsletters (including [your name and email
        address);
      </div>
      <div>
        Information that you provide to us when using the services on our
        website, or that is generated in the course of the use of those services
        (including the timing, frequency and pattern of service use);
      </div>
      <div>
        Information contained in or relating to any communications that you send
        to us or send through enquiry forms or otherwise on our website
        (including the communication content and meta data associated with the
        communication);
      </div>
      <div>
        Any other personal information that you choose to send to us. Before you
        disclose to us the personal information of another person, you must
        obtain that person's consent to both the disclosure and the processing
        of that personal information in accordance with this policy.
      </div>
      <div>Using Your Personal Information</div>
      <div>
        Personal information submitted to us through our website will be used
        for the purposes specified in this policy or on the relevant pages of
        the website.
      </div>
      <div>We may use your personal information to:</div>
      <div>Administer our website and business;</div>
      <div>Personalize our website for you;</div>
      <div>Enable your use of the services available on our website;</div>
      <div>
        Send you email notifications that you have specifically requested;
      </div>
      <div>
        Send you our email newsletter, Scheme & offer’s if you have requested
        it;
      </div>
      <div>
        Deal with enquiries and complaints made by or about you relating to our
        website;
      </div>
      <div>Keep our website secure and prevent fraud.</div>
      <div>
        If you submit personal information for publication on our website, we
        will publish and otherwise use that information in accordance with the
        license you grant to us.
      </div>
      <div>Disclosing Personal Information</div>
      <div>
        We may disclose your personal information to any member of our group of
        companies (this means our affiliates and subsidiaries,) insofar as
        reasonably necessary for the purposes set out in this policy.
      </div>
      <div>To the extent that we are required to do so by law;</div>
      <div>
        In order to establish, exercise or defend our legal rights (including
        providing information to others for the purposes of fraud prevention and
        reducing credit risk);
      </div>
      <div>
        To the purchaser (or prospective purchaser) of any business or asset
        that we are (or are contemplating) selling; and
      </div>
      <div>
        To any person who we reasonably believe may apply to a court or other
        competent authority for disclosure of that personal information where,
        in our reasonable opinion, such court or authority would be reasonably
        likely to order disclosure of that personal information.
      </div>
      <div>
        Except as provided in this policy, we will not provide your personal
        information to third parties.
      </div>
      <div>Retaining Personal Information</div>
      <div>
        This Section sets out our data retention policies and procedure, which
        are designed to help ensure that we comply with our legal obligations in
        relation to the retention and deletion of personal information.
      </div>
      <div></div>
      <div>
        Personal information that we process for any purpose or purposes shall
        not be kept for longer than is necessary for that purpose or those
        purposes.
      </div>
      <div>
        Notwithstanding the other provisions of this Section 6, we will retain
        documents (including electronic documents) containing personal data:
      </div>
      <div>To the extent that we are required to do so by law;</div>
      <div>
        If we believe that the documents may be relevant to any ongoing or
        prospective legal proceedings; and
      </div>
      <div>
        In order to establish, exercise or defend our legal rights (including
        providing information to others for the purposes of fraud prevention and
        reducing credit risk).
      </div>
      <div>Protection of Information, Storage & Cookies:</div>
      <div>
        We will take reasonable technical and organizational precautions to
        prevent the loss, misuse or alteration of your personal information.
      </div>
      <div>
        You acknowledge that the transmission of information over the internet
        is inherently insecure, and we cannot guarantee the security of data
        sent over the internet.
      </div>
      <div>
        Our website includes hyperlinks to, and details of, third party
        websites.
      </div>
      <div>
        We have no control over, virus attacks and your personal data may be at
        risk and are not responsible for, the privacy policies and practices of
        third parties.
      </div>
      <div>
        CONZARC may place a text file called a "cookie" in the browser files.
        The cookie itself does not contain Personal Information although it will
        enable the CONZARC Service to relate your use of the CONZARC Service to
        information that you have specifically and knowingly provided to
        CONZARC.
      </div>
      <div>
        The Cookies enables CONZARC to remember your choices and some data field
        contents which you would be required to fill-in.
      </div>
      <div>Privacy Policy Changes:</div>
      <div>
        CONZARC have and continue to have the right to modify, change or update
        Privacy Policy at any time. However, CONZARC will use your Personal
        Information in a manner consistent with Privacy Policy at the time you
        shared Personal Information. You are encouraged to check the CONZARC
        Service often to get updated about Privacy Policy. You acknowledge that
        by virtue of usage of the CONZARC Service you provide acceptance of the
        Privacy Policy. All questions to be addressed to{" "}
        <a href="mailto: admin@conzarc.com">admin@conzarc.com</a>
      </div>
      <div>
        If you have any questions about this Privacy Policy, the practices of
        this site, or your dealings with this site, please contact us at:
      </div>
      <div>CONZARC</div>
      <div>
        <a href="www.conzarc.com">www.conzarc.com</a>
      </div>
    </div>
  );
};

export default PrivacyContent;
