import React from "react";
import ConstructionCarousel from "./DetailsCarousel";
import ConstructionContent from "./DetailsContent";

function ConstructionDetails() {
  return (
    <div className="service-details-content-wrapper pl-0 pl-md-4">
      <ConstructionCarousel />
      <ConstructionContent />
      <div className="faq-content pl-0 pl-md-4">
        <div className="service-details-plumbing">
          Best quality Products
        </div>
        <div className="service-details-plumbing">Effective Research</div>
        <div className="service-details-plumbing">Skilled Workforce</div>
        <div className="service-details-plumbing">
          We put technicalities and documentation first
        </div>
        <div className="service-details-plumbing">
          Design with a Creative Mindset
        </div>
        <div className="service-details-plumbing">
          Structural Integrity Analysis
        </div>
        <div className="service-details-plumbing">Prompt Delivery</div>
        <div className="service-details-plumbing">
          Multi-disciplined worker & contractor
        </div>
        <div className="service-details-plumbing">
          Optimization of design &amp; quality characteristics
        </div>
        <div className="service-details-plumbing">
          Establish and calculate the scope of work
        </div>
        <div>And it's all done on a reasonable budget.</div>
        <div>
          What are you waiting for? Send us a message, or call us for a quote.
          Use our contact form to tell us more about your project, and we will
          connect you with the services to fit your needs.
        </div>
        <div>Ready to create you’re Dream House!</div>
        <div>
          <strong>We've arrived at your doorway; relax and count on us</strong>
        </div>
      </div>
    </div>
  );
}

export default ConstructionDetails;
