import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { signin } from "../actions/sellerActions";
import SellerSteps from "../components/SellerSteps";
import Loading from "../components/Loading";

export default function SellerSigninpage(props) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const sellerSignin = useSelector((state) => state.sellerSignin);
  const { loading, sellerInfo, error } = sellerSignin;
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    if (sellerInfo) {
      history.replace("/createshop/" + sellerInfo._id);
    }
    return () => {
      //
    };
  }, [sellerInfo]);

  const submitHandler = (e) => {
    e.preventDefault();
    dispatch(signin(email, password));
  };

  return (
    <center>
      <SellerSteps step1 />
      <div className="form_container">
        <form onSubmit={submitHandler}>
          <h3 className="form__title">Seller Sign-In</h3>
          <li>
            {loading && (
              <div style={{ padding: "80px" }}>
                <Loading />
              </div>
            )}
            {error && <div>{error}</div>}
          </li>
          <div className=" col-12">
            <div className="single-personal-info">
              <label for="email">Email:</label>
              <br />
              <input
                name="email"
                id="email"
                className="form__input sigin-input-register"
                label="Email"
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
          </div>
          <div className=" col-12">
            <div className="single-personal-info">
              <label for="password">Password:</label>
              <br />
              <input
                type="password"
                id="password"
                name="password"
                className="form__input sigin-input-register"
                onChange={(e) => setPassword(e.target.value)}
                label="Password"
              />
            </div>
          </div>

          <button type="submit" className="shop-search-button">
            Signin
          </button>

          <div style={{ textAlign: "center" }}>New as a Seller on Conzarc?</div>
          <div style={{ textAlign: "center" }}>
            <Link
              to="/seller/register"
              className="button secondary text-center"
            >
              Create your Conzarc Seller account
            </Link>
          </div>
        </form>
      </div>
    </center>
  );
}
