import React from "react";
import { SRLWrapper } from "simple-react-lightbox";
import img1 from "../../assets/img/gallery/5.jpg";
import img2 from "../../assets/img/gallery/6.jpg";

function HomeMaintenanceContent() {
  return (
    <>
      <p>
        Most of the homeowners have to wander many places for their home related
        care, repair and solution, but now there is no need to panic, be it
        repair or care of your home or any kind of problem in your home. Conzarc
        brings you all your home solutions, care and updates under one roof.
      </p>

      <p>
        Whether our residential property maintenance services range from
        structural repairs, commercial or residential property maintenance, to
        complex maintenance tasks, simple or large, our skilled workforce will
        be available to meet your every need.
      </p>

      <p>
        Conzarc makes your home maintenance easy. Our experts consult about home
        maintenance and future problems to provide you with an unbeatable
        service, right direction guidance, value for money and lasting results
      </p>
      <SRLWrapper>
        <div className="row">
          <div className="col-md-6 col-12">
            <a href={img1} className="popup-gallery">
              <img src={img1} alt="" />
            </a>
          </div>
          <div className="col-md-6 col-12">
            <a href={img2} className="popup-gallery">
              <img src={img2} alt="" />
            </a>
          </div>
        </div>
      </SRLWrapper>
    </>
  );
}

export default HomeMaintenanceContent;
