import React from "react";
import { SRLWrapper } from "simple-react-lightbox";
import img1 from "../../assets/img/gallery/5.jpg";
import img2 from "../../assets/img/gallery/6.jpg";

function DetailsContent() {
  return (
    <>
      <p>
        Designing and decorating a home or office is something that will make
        you felt enthusiastic and nervous at the same time? You see different
        designs at different homes and offices but cannot decide on one. You
        need an expert! Our team has been able to meet the expectations of the
        customers by providing high quality results with some of the best
        painting and decorations
      </p>
      <h2>High Quality Painting &amp; Decorating Services</h2>
      <p>
        Whether you are decorating your home for festivals, a wedding at home or
        your special day. Whether it is routine maintenance or occasional work
        needs, we are available as a reliable and best decorating and painting
        services provider.
      </p>
      <p>
        Apart from the highest quality of our painting and decoration services,
        you can rest assured to know that our team of professionals fully
        trained to the highest standards and uses the latest equipment and best
        materials.
      </p>
      <p>
        Only an experienced hand and a trained professional can fulfill the
        responsibility of balancing its reliability, quality, and low cost for
        effective painting and decoration.
      </p>
      <SRLWrapper>
        <div className="row">
          <div className="col-md-6 col-12">
            <a href={img1} className="popup-gallery">
              <img src={img1} alt="" />
            </a>
          </div>
          <div className="col-md-6 col-12">
            <a href={img2} className="popup-gallery">
              <img src={img2} alt="" />
            </a>
          </div>
        </div>
      </SRLWrapper>
    </>
  );
}

export default DetailsContent;
