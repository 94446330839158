import React from 'react'
import { FaSpinner } from "react-icons/fa";

const Loading = () => {
  return (
    <center className="loading-container">
    <FaSpinner icon="spinner" className="spinner" size={70} />
  </center>
  )
}

export default Loading