import React from "react";
import { SRLWrapper } from "simple-react-lightbox";
import img1 from "../../assets/img/gallery/5.jpg";
import img2 from "../../assets/img/gallery/6.jpg";

function CarpentaryContent() {
  return (
    <>
      <h2>SERVICE DESCRIPTION</h2>
      <p>
        We specialize in carpentry work using modern and traditional tools for
        optimum results. At Conzarc all the work is done by our professional
        carpenters who are well trained, verified, educated, clever and
        disciplined. Their expertise is in refurbishment, repair and maintenance
        of your furniture and many other wooden items. Our services also include
        repairing and refurbishing old furniture to give it a new design and
        make furniture crafted to your taste and space.
      </p>
      <SRLWrapper>
        <div className="row">
          <div className="col-md-6 col-12">
            <a href={img1} className="popup-gallery">
              <img src={img1} alt="" />
            </a>
          </div>
          <div className="col-md-6 col-12">
            <a href={img2} className="popup-gallery">
              <img src={img2} alt="" />
            </a>
          </div>
        </div>
      </SRLWrapper>
    </>
  );
}

export default CarpentaryContent;
