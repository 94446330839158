import React, { useEffect, useState } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { signin } from "../actions/userActions";
import Loading from "../components/Loading";

export default function UserSigninpage(props) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  let { search } = useLocation();
  const userSignin = useSelector((state) => state.userSignin);
  const { loading, userInfo, error } = userSignin;
  const redirect = search ? search.split("=")[1] : "/shop";
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    if (userInfo) {
      // props.history.push(redirect);
      history.push(redirect);
    }
    return () => {
      //
    };
  }, [userInfo]);

  const submitHandler = (e) => {
    e.preventDefault();
    dispatch(signin(email, password));
  };

  return (
    <center>
      <div className="form_container">
        <form onSubmit={submitHandler}>
          <h3 className="form__title">Login</h3>
          <br/>
          <li>
            {loading && (
              <div>
                {/* Loading */}
                <div style={{ padding: "80px" }}>
                  <Loading />
                </div>
              </div>
            )}
            {error && <div>{error}</div>}
          </li>
          <div className=" col-12">
            <div className="single-personal-info">
              <label for="username">Email: </label>
              <br />
              <input
                name="email"
                id="email"
                label="Email"
                className="form__input sigin-input-register"
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
          </div>
          <div className=" col-12">
            <div className="single-personal-info">
              <label for="password">Password</label>
              <br />
              <input
                type="password"
                id="password"
                name="password"
                className="form__input sigin-input-register"
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
          </div>
          <br/>
          <button type="submit" className="shop-search-button">
            Signin
          </button>
          <br/>
          <div>New to Conzarc</div>
          <div style={{ textAlign: "center" }}>
            <Link>
              <button
              className="user-sign-new-button"
                onClick={(e) => {
                  e.preventDefault();
                  history.push("/user/register");
                }}
              >
                Create your Conzarc account
              </button>
            </Link>
          </div>
        </form>
      </div>
    </center>
  );
}
