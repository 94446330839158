import React from 'react';

function AppointmentItem({ day, time }) {
    return (
        <div className="appointment-time d-flex justify-content-between">
            <span>{day}</span>
            <span>{time}</span>
        </div>
    );
}

export default AppointmentItem;
