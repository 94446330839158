import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { listProducts } from "../actions/shopActions";
import { useParams } from "react-router-dom";
import Navstuff from "../components/Navstuff.js";
import Loading from "../components/Loading.jsx";
import PageBanner from "../../components/PageBanner";
import { Product } from "../components/Product.js";

export default function Shoppage(props) {
  let { id } = useParams();

  const productList = useSelector((state) => state.productList);
  const { products, loading } = productList;
  const dispatch = useDispatch();
  let data = products;
  var pcategory = new Set();

  const [category, setCategory] = React.useState("All");

  useEffect(() => {
    dispatch(listProducts(id));
    return () => {
      //
    };
  }, [category]);

  function funct(x) {
    if (category !== "All") {
      return x.pcategory === category;
    }
    if (category === "All") {
      return x.pcategory === x.pcategory;
    } else {
      return x.pcategory === x.pcategory;
    }
  }

  products
    ? products.productItems
      ? pcategory.add(products.productItems.map((x) => x.pcategory))
      : console.log(loading)
    : console.log(loading);
  var cat = [...pcategory];
  cat = [...new Set(cat[0])];
  console.log(cat);
  products
    ? products.productItems
      ? (data = products.productItems.filter((x) => funct(x)))
      : console.log(loading)
    : console.log(loading);

  return loading ? (
    <div>
      {/* Loading */}
      <div style={{ padding: "80px" }}>
        <Loading />
      </div>
    </div>
  ) : (
    <div>
      <Navstuff />
      <PageBanner
        bannerBg="https://images.unsplash.com/photo-1589939705384-5185137a7f0f?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80"
        heading={products.name}
        currentPage="Shop"
      />
      <div
        style={{
          paddingLeft: 50,
          paddingRight: 50,
          paddingTop: 20,
          paddingBottom: 10,
        }}
      ></div>

      <Product data={data} id={id}></Product>
      <br />
    </div>
  );
}
