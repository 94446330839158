import React from "react";
import { Link } from "react-router-dom";

function WidExplore() {
  return (
    <div className="single-footer-wid">
      <div className="wid-title">
        <h4>Explore Other Pages</h4>
      </div>
      <div className="splite-menu d-flex justify-content-between">
        <ul>
          <li>
            <Link to="/">Home</Link>
          </li>
          <li>
            <Link to="/about">About</Link>
          </li>
          <li>
            <Link to="/services">Services</Link>
          </li>
          <li>
            <Link to="/privacy">Privacy</Link>
          </li>
          <li>
            <Link to="/terms">T&C</Link>
          </li>
          <li>
            <Link to="/shipping">Shipping</Link>
          </li>
          
        </ul>
        <ul>
          <li>
            <Link to="/contact">Contact</Link>
          </li>
          <li>
            <Link to="/shop">Shop</Link>
          </li>
          <li>
            <Link to="/safety-policy">Safety Policy</Link>
          </li>
          <li>
            <Link to="/quality">Quality Policy</Link>
          </li>
          <li>
            <Link to="/complaints">Complaints</Link>
          </li>
          <li>
            <Link to="/return-and-cancellation-policy">Return & Cancellation</Link>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default WidExplore;
