import { v4 as uuidv4 } from "uuid";

const processItemData = [
  {
    id: uuidv4(),
    number: "01",
    heading: "Caters to all your requirements",
    text: "Here we believe in the uniqueness of an individual’s vision and curate services with our heart and soul based on our client&#39;s needs.",
  },

  {
    id: uuidv4(),
    number: "02",
    heading: "Fits everyone’s budget",
    text: "We are here to fulfil dreams, and we understand that everyone's budget is unique. You will never be disappointed and get top-notch work within your budget. Also, we make sure that you are aware about the total cost estimate so that you don’t feel backed into a corner in the future.",
  },

  {
    id: uuidv4(),
    number: "03",
    heading: "Checking in timely through multi-channel support",
    text: "We believe in in-person meetings to understand clients needs to deliver you the best quality work. Refurbishing to reconstruction, we offer anything and everything you need.",
  },

  {
    id: uuidv4(),
    number: "04",
    heading: "Reliability in work execution",
    text: "Proper work execution requires regular quality checks pre, during, and post-delivery. We monitor each step of the process and maintain complete transparency with our clients.",
  },
  {
    id: uuidv4(),
    number: "05",
    heading: "Timely delivery, satisfied clients",
    text: "Our mottoes are &quot;blissful delivery&quot; and &quot;satisfied clients.&quot; We offer round-the-clock services and strive to provide timely assistance to our clients. We use cutting-edge technology and modern equipment to maximize customer satisfaction.",
  },
];

export default processItemData;
