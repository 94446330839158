import React from "react";

const HomeShop = ({ shop }) => {
  return (
    <div className="news-card">
      <img src={shop.image} alt="" className="news-card__image" />
      <div className="news-card__text-wrapper">
        <h2 className="news-card__title">{shop.name}</h2>
        <div className="news-card__post-date">{shop.address1}</div>
        <div className="news-card__details-wrapper">
          <p className="news-card__excerpt">{shop.description}&hellip;</p>
        </div>
      </div>
    </div>
  );
};

export default HomeShop;
