import React from "react";
import Footer3 from "../components/Footer3";
import Header3 from "../components/Header3";
import PageBanner from "../components/PageBanner";
import bannerBg from "../assets/img/page-banner.jpg";
import QualityImage from "../assets/img/Quality-Policy.jpg";

function QualityPolicy() {
  return (
    <>
      <Header3></Header3>
      <PageBanner
        bannerBg={bannerBg}
        heading="Quality Policy"
        currentPage="Quality Policy"
      />
      <center>
        <img src={QualityImage} alt=""/>
      </center>
      <Footer3 />
    </>
  );
}

export default QualityPolicy;
