import React from "react";
import { SRLWrapper } from "simple-react-lightbox";
import img1 from "../../assets/img/gallery/5.jpg";
import img2 from "../../assets/img/gallery/6.jpg";

function DesignPlanningContent() {
  return (
    <>
      <p>
        We work closely with our clients and believe this relationship is
        fundamental in achieving an end result that sums up and creates spaces
        that are enjoyable to live and work in.
      </p>
      <p>
        To ensure planned construction, the engineers at the Design Center have
        developed all phases of the drawings and specifications, including
        sections-
      </p>
      <SRLWrapper>
        <div className="row">
          <div className="col-md-6 col-12">
            <a href={img1} className="popup-gallery">
              <img src={img1} alt="" />
            </a>
          </div>
          <div className="col-md-6 col-12">
            <a href={img2} className="popup-gallery">
              <img src={img2} alt="" />
            </a>
          </div>
        </div>
      </SRLWrapper>
    </>
  );
}

export default DesignPlanningContent;
