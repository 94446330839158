import React from "react";
import { Navbar, Nav, Dropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import Cookie from "js-cookie";
import logo from "../../assets/img/logo-3.png";
import { withRouter } from "react-router-dom";
import { BiCartAlt } from "react-icons/bi";

function Navstuff(props) {
  const userSignin = useSelector((state) => state.userSignin);
  const { userInfo } = userSignin;
  const sellerSignin = useSelector((state) => state.sellerSignin);
  const { sellerInfo } = sellerSignin;

  const cart = useSelector((state) => state.cart);
  const { cartItems } = cart;

  const logout = () => {
    Cookie.set("userInfo", null);
    Cookie.set("sellerInfo", null);
    Cookie.set("cartItems", null);
  };

  const login = () => {
    if (sellerInfo || userInfo) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <div style={{ position: "sticky", top: "0", zIndex: "99999" }}>
      <Navbar bg="white" variant="light" expand="lg">
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Brand href="/">
          <img
            alt=""
            src={logo}
            width="175"
            height="70"
            className="d-inline-block align-top"
          />
        </Navbar.Brand>

        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="mr-auto">
            {"  "}
            {sellerInfo ? (
              <>
                <Nav.Link href={`/addproducts/${sellerInfo._id}`}>
                  Manage Inventory
                </Nav.Link>
                <Nav.Link href={`/seller/orders/${sellerInfo._id}`}>
                  Manage Orders
                </Nav.Link>
                <Nav.Link href={`/wallet/${sellerInfo._id}`}>Wallet</Nav.Link>
              </>
            ) : (
              <Nav.Link href="/seller/register">
              <button
              className="shop-search-button"
          
            >
             Create Shop
            </button>
                {/* <div className="create-your-own-shop"></div> */}
              </Nav.Link>
            )}
          </Nav>
        </Navbar.Collapse>
        <br></br>
        <Navbar.Collapse className="justify-content-end">
          {userInfo || sellerInfo ? (
            <div></div>
          ) : (
            <a href="/user/register">Sign Up</a>

            // <Link to='/users/signin'>Sign In</Link>
          )}{" "}
          {userInfo || sellerInfo ? (
            <>
              <h5>
                {" "}
                Hi,{!sellerInfo ? <>{userInfo.name}</> : <>{sellerInfo.name}</>}
              </h5>
              <a
                onClick={logout}
                href="/"
                variant="outlined"
                style={{ margin: 20 }}
              >
                Logout
              </a>
            </>
          ) : (
            <Dropdown drop="down" margin="20" className="shop-menu-drop" >
              <Dropdown.Toggle
                variant="success"
                id="collasible-nav-dropdown"
                className="shop-menu-signup-button"
              >
                Sign In
              </Dropdown.Toggle>

              <Dropdown.Menu className="shop-menu-signup-button-menu">
                <Dropdown.Item href="/seller/signin">Seller</Dropdown.Item>
                <Dropdown.Item href="/user/signin">User</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          )}
          {sellerInfo ? <div></div> : <div></div>}
        </Navbar.Collapse>
        <br />
        <Link to="/cart">  
            <BiCartAlt style={{fontSize:"30px", color:"#ffc54f", marginRight:"2rem"}}/>
        </Link>
      </Navbar>
    </div>
  );
}

export default withRouter(Navstuff);
