import Axios from "axios";
import Cookie from "js-cookie";
import {
  SELLER_SIGNIN_REQUEST,
  SELLER_SIGNIN_SUCCESS,
  SELLER_SIGNIN_FAIL,
  SELLER_REGISTER_REQUEST,
  SELLER_REGISTER_SUCCESS,
  SELLER_REGISTER_FAIL,
} from "../constants/sellerConstants";
const URL = "https://conzarc.herokuapp.com";
const signin = (email, password) => async (dispatch) => {
  dispatch({ type: SELLER_SIGNIN_REQUEST, payload: { email, password } });
  try {
    const { data } = await Axios.post(`${URL}/api/seller/signin`, {
      email,
      password,
    });
    dispatch({ type: SELLER_SIGNIN_SUCCESS, payload: data });
    alert("Sign IN Success");
    Cookie.set("sellerInfo", JSON.stringify(data));
  } catch (error) {
    dispatch({ type: SELLER_SIGNIN_FAIL, payload: error.message });
  }
};

const register =
  (
    name,
    email,
    password,
    rePassword,
    sphone,
    bankAccountNumber,
    bankName,
    upiNumber
  ) =>
  async (dispatch) => {
    dispatch({
      type: SELLER_REGISTER_REQUEST,
      payload: { name, email, password, sphone },
    });
    if (rePassword !== password) {
      dispatch({
        type: SELLER_REGISTER_FAIL,
        payload: "Passwords don't match",
      });
    }
    try {
      const { data } = await Axios.post(`${URL}/api/seller/register`, {
        name,
        email,
        password,
        sphone,
        bankAccountNumber,
        bankName,
        upiNumber,
      });
      dispatch({ type: SELLER_REGISTER_SUCCESS, payload: data });
      alert("Success. Please Sign In to Continue");
      Cookie.set("sellerInfo", JSON.stringify(data));
    } catch (error) {
      dispatch({ type: SELLER_REGISTER_FAIL, payload: error.message });
    }
  };

export { signin, register };
