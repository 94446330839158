import { AiOutlineMail, AiOutlinePhone } from 'react-icons/ai';
import { FaMapMarkerAlt } from 'react-icons/fa';
import { v4 as uuidv4 } from 'uuid';

const contactData = [
    {
        id: uuidv4(),
        icon: <AiOutlineMail />,
        heading: 'Email Address',
        text: 'Sent mail asap anytime',
        item1: 'admin@conzarc.com',
        item2: 'info@conzarc.com',
    },

    {
        id: uuidv4(),
        icon: <AiOutlinePhone />,
        heading: 'Phone Number',
        text: 'Call Us Anytime',
        item1: '7087835461',
        item2: '.',
    },

    {
        id: uuidv4(),
        icon: <FaMapMarkerAlt />,
        heading: 'Office Address',
        text: 'Visit us Anytime',
        item1: 'Shivalik Avenue,1-B,Rampur Sahni,Sector-2,Naya nangal,Distt Ropar- Punjab 140126',
        item2: '',
    },
];

export default contactData;
