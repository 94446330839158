import {
  FaDraftingCompass,
  FaRegBuilding,
  FaTruckMoving,
} from "react-icons/fa";
import { v4 as uuidv4 } from "uuid";
import thumb1 from "../../assets/img/service1.jpeg";
import thumb2 from "../../assets/img/service2.jpeg";
import thumb3 from "../../assets/img/service3.jpeg";
import thumb4 from "../../assets/img/service4.jpeg";
import thumb5 from "../../assets/img/service5.jpeg";
import thumb6 from "../../assets/img/service6.jpeg";
import thumb7 from "../../assets/img/service7.jpeg";
import thumb8 from "../../assets/img/service8.jpeg";

const servicesTwoData = [
  {
    id: uuidv4(),
    thumbnail: thumb1,
    icon: <FaDraftingCompass />,
    heading: "Painting and Decorations",
    text: "Designing and decorating a home or office is something that will make you felt enthusiastic and nervous at the same time? You see different designs at different homes and offices but cannot decide on one. You need an expert!",
    link: "/painting-and-decorations",
  },

  {
    id: uuidv4(),
    thumbnail: thumb2,
    icon: <FaRegBuilding />,
    heading: "Garden Maintenance",
    text: "We provide experienced and skilled gardeners to take care of your garden in a professional manner and maintain its beauty, who can advise you about the nursery suitable for every season and keep your garden green and beautiful.",
    link: "/garden",
  },

  {
    id: uuidv4(),
    thumbnail: thumb3,
    icon: <FaTruckMoving />,
    heading: "Plumbing",
    text: "Our highly qualified plumbers have years of experience working in both residential and commercial properties.We pride ourselves in providing technical, cost-effective and efficient plumbing service.",
    link: "/plumbing",
  },
  {
    id: uuidv4(),
    thumbnail: thumb4,
    icon: <FaTruckMoving />,
    heading: "Home Maintenance",
    text: "Most of the homeowners have to wander many places for their home related care, repair and solution,but now there is no need to panic, be it repair or care of your home or any kind of problem in your home.",
    link: "/home-maintenance",
  },
  {
    id: uuidv4(),
    thumbnail: thumb5,
    icon: <FaTruckMoving />,
    heading: "Construction",
    text: "Are you screaming because you are not getting all the Construction Support under one roof? If you are tired of looking for reliable construction services and want to rely on a service provider to solve all your construction concerns. No need to scratch your head now. We will help you end to end.We hold your hand till the end.",
    link: "/construction",
  },
  {
    id: uuidv4(),
    thumbnail: thumb6,
    icon: <FaTruckMoving />,
    heading: "Construction Material",
    text: "At Conzarc we as our construction material supplies are responsible for providing excellent construction material to our clients at reasonable prices, without any middlemen. We want our customer to get the construction related product at one place, reasonable price, good brand, and best quality for construction,",
    link: "/construction-material",
  },
  {
    id: uuidv4(),
    thumbnail: thumb7,
    icon: <FaTruckMoving />,
    heading: "Carpentary",
    text: "Are you’re looking for carpenter who your meet higher expectations Are you planning to make an improvement to your house or your business premises? Are you looking for a carpentry services for your woodwork? Conzarc offer carpentry services to the homeowner and business owners.",
    link: "/carpentary",
  },
  {
    id: uuidv4(),
    thumbnail: thumb8,
    icon: <FaTruckMoving />,
    heading: "Design and Planning",
    text: "Designing, which is the most important step in any item&#39;s realization process, is one of Conzarc constantly promoted activities. At Conzarc we provide a professional end-to-end service, concept completion through design and customizing cost-effective solutions.",
    link: "/design-and-planning",
  },
];

export default servicesTwoData;
