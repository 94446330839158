import { v4 as uuidv4 } from 'uuid';
import icon1 from '../../assets/img/icon/factory.png';

const videoFeaturedData = [
    {
        id: uuidv4(),
        icon: icon1,
        heading: 'Industrial Solutions',
       
    },

    {
        id: uuidv4(),
        icon: icon1,
        heading: 'Construction',
      
    },

    {
        id: uuidv4(),
        icon: icon1,
        heading: 'Construction Solutions',
        
    },

    {
        id: uuidv4(),
        icon: icon1,
        heading: 'Maintenance services',
        
    },
];

export default videoFeaturedData;
