import React from 'react';
import PlumbingDetailsCarousel from './DetailsCarousel';
import PlumbingDetailsContent from './DetailsContent';

function PlumbingDetails() {
    return (
        <div className="service-details-content-wrapper pl-0 pl-md-4">
            <PlumbingDetailsCarousel />
            <PlumbingDetailsContent />
            <div className="faq-content pl-0 pl-md-4">
                <h2>SERVICE LIST</h2>
                <div className="service-details-plumbing">Repairing leaking taps, pipes</div>
                <div className="service-details-plumbing">Repairing dripping taps</div>
                <div className="service-details-plumbing">Installing basins, taps, baths &amp; toilets</div>
                <div className="service-details-plumbing">New bathroom installations</div>
                <div className="service-details-plumbing">New kitchen installations</div>
                <div className="service-details-plumbing">Installing new pumps</div>
                <div className="service-details-plumbing">Fitting garden taps</div>
                <div className="service-details-plumbing">Consultation</div>
                <div className="service-details-plumbing">Fitting toilet seats</div>
                <div className="service-details-plumbing">Unblocking sinks and toilets</div>
            </div>
        </div>
    );
}

export default PlumbingDetails;
