import React, { useCallback, useEffect } from "react";


export default function Payment() {

  return (
    <div className="payment__container">
      

    </div>
  );
}
