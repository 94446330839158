import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { saveProduct, listShops, deleteProduct } from "../actions/shopActions";
import Loading from "../components/Loading";
import { BsFillTrashFill } from "react-icons/bs";
import { AiFillEdit } from "react-icons/ai";

export default function Sellerproductspage(props) {
  let { id } = useParams();
  let sellerid = id;

  const [modalVisible, setModalVisible] = useState(false);
  const [pname, setPName] = useState("");
  const [pdesc, setPDesc] = useState("");
  const [pcategory, setCategory] = useState("");
  const [pprice, setPPrice] = useState("");
  const [p_id, setId] = useState("");
  const [image, setImage] = useState("");

  const productSave = useSelector((state) => state.productSave);
  const {
    loading: loadingSave,
    success: successSave,
    error: errorSave,
  } = productSave;

  const shopList = useSelector((state) => state.shopList);
  const { shops, loading } = shopList;
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(listShops());
    if (successSave) {
      //props.history.push("/addproducts/"+id);
      setModalVisible(false);
    }
    return () => {
      //
    };
  }, [successSave]);

  const openModal = (product) => {
    setModalVisible(true);
    setId(product._id);
    setPName(product.pname);
    setPDesc(product.pdesc);
    setPPrice(product.pprice);
    setImage(product.image);
    setCategory(product.pcategory);
  };

  const submitHandler = (e) => {
    e.preventDefault();
    let formData = new FormData();
    formData.append("pname", pname);
    formData.append("pdesc", pdesc);
    formData.append("pprice", pprice);
    formData.append("image", image);
    formData.append("pcategory", pcategory);
    dispatch(saveProduct(formData, sellerid));
  };

  //getting the particular productItems from the whole shop object via Seller_id
  let products;
  if (!loading) {
    let selleridstring = "" + sellerid;
    let object = shops.find((x) => x.seller_id === selleridstring);
    if (object) {
      products = object.productItems;
    }
  }

  const UploadFileHandler = (e) => {
    const file = e.target.files[0];
    setImage(file);
  };

  const deleteHandler = (product) => {
    dispatch(deleteProduct({ prod_id: product._id, sel_id: sellerid }));
  };

  useEffect(() => {
    console.log(sellerid, "seller");

    return () => {};
  }, []);

  return (
    <div className="content content-margined">
      <div responsive="true" pad="small">
        <center>
          <h3>Manage Shop Inventory</h3>
        </center>
      </div>

      {!modalVisible && (
        <div style={{ padding: 12 }}>
          <button className="shop-search-button" onClick={() => openModal({})}>
            Add Products
          </button>
        </div>
      )}

      {/* FORM START */}
      {modalVisible && (
        <center>
          <div className="form">
            <form onSubmit={submitHandler}>
              <ul className="form-container">
                <li>
                  <h3 className="form__title">Add Products/Items</h3>
                </li>
                <li>
                  {loadingSave && <div>Loading...</div>}
                  {errorSave && <div>{errorSave}</div>}
                </li>
                <div className="col-md-6 col-12">
                  <div className="single-personal-info">
                    <label htmlFor="name">Product Name</label>
                    <br />
                    <input
                      type="text"
                      className="form__input sigin-input"
                      name="pname"
                      value={pname}
                      id="pname"
                      onChange={(e) => setPName(e.target.value)}
                    ></input>
                  </div>
                </div>
                <div className="col-md-6 col-12">
                  <div className="single-personal-info">
                    <label htmlFor="description">Product Description</label>
                    <br />
                    <input
                      name="description"
                      className="form__input sigin-input"
                      value={pdesc}
                      id="pdesc"
                      onChange={(e) => setPDesc(e.target.value)}
                    ></input>
                  </div>
                </div>
                <div className="col-md-6 col-12">
                  <div className="single-personal-info">
                    <label htmlFor="description">Product Category</label>
                    <br />
                    <input
                      name="pcategory"
                      className="form__input sigin-input"
                      value={pcategory}
                      id="pcategory"
                      onChange={(e) => setCategory(e.target.value)}
                    ></input>
                  </div>
                </div>
                <div className="col-md-6 col-12">
                  <div className="single-personal-info">
                    <label htmlFor="image">Product Image</label>
                    <br />
                    <input
                      accept="image/*"
                      onChange={UploadFileHandler}
                      id="icon-button-file"
                      type="file"
                    />
                  </div>
                </div>
                <br />
                <div className="col-md-6 col-12">
                  <div className="single-personal-info">
                    <label htmlFor="name">Product Price</label>
                    <br />
                    <input
                      type="text"
                      name="price"
                      value={pprice}
                      id="pprice"
                      className="form__input sigin-input"
                      onChange={(e) => setPPrice(e.target.value)}
                    ></input>
                  </div>
                </div>

                <button type="submit" className="shop-search-button">
                  Create Product
                </button>

                <button
                  type="button"
                  onClick={() => setModalVisible(false)}
                  className="shop-search-button-red"
                >
                  Back
                </button>
              </ul>
            </form>
          </div>
        </center>
      )}
      {/* FORM END */}

      {!modalVisible ? (
        <div>
          {products ? (
            <center>
              <table className="product-table">
                <tr>
                  <th className="product-table-td">Product ID</th>
                  <th className="product-table-td">Product Name</th>
                  <th className="product-table-td">Price</th>
                  <th className="product-table-td">Description</th>
                  <th className="product-table-td">Action</th>
                </tr>
                <tbody>
                  {products.map((product) => (
                    <tr key={product._id}>
                      <td className="product-table-td">{product._id}</td>
                      <td className="product-table-td">{product.pname}</td>
                      <td className="product-table-td">{product.pprice}</td>
                      <td className="product-table-td">{product.pdesc}</td>
                      <td className="product-table-td">
                        <div direction="row" justify="center">
                          <AiFillEdit
                            className="edit-product-icon"
                            onClick={() => openModal(product)}
                          />
                          <BsFillTrashFill
                            className="delete-product-icon"
                            onClick={() => deleteHandler(product)}
                          />
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </center>
          ) : (
            <div>
              {/* Loading */}
              <div style={{ padding: "80px" }}>
                {" "}
                <Loading />{" "}
              </div>
            </div>
          )}
        </div>
      ) : (
        <div></div>
      )}
    </div>
  );
}
