import { v4 as uuidv4 } from 'uuid';
import member1 from '../../assets/img/team/team1.png';
import member2 from '../../assets/img/team/team2.png';
import member3 from '../../assets/img/team/team3.png';
import member4 from '../../assets/img/team/team4.png';

const teamData = [
    {
        id: uuidv4(),
        img: member4,
        name: 'B. Baibhav',
        position: 'Business Head',
        fbLink: '/',
        twitterLink: '/',
        behanceLink: '/',
        youtubeLink: '/',
    },
    {
        id: uuidv4(),
        img: member1,
        name: 'ANURAG SAINI',
        position: 'Account Executive',
        fbLink: '/',
        twitterLink: '/',
        behanceLink: '/',
        youtubeLink: '/',
    },

    {
        id: uuidv4(),
        img: member2,
        name: 'KAMAL TETRIYA',
        position: 'Design and Development',
        fbLink: '/',
        twitterLink: '/',
        behanceLink: '/',
        youtubeLink: '/',
    },

    {
        id: uuidv4(),
        img: member3,
        name: 'ANKAJ KUMAR',
        position: 'Civil Engineer & Opertaions Head',
        fbLink: '/',
        twitterLink: '/',
        behanceLink: '/',
        youtubeLink: '/',
    },

    
];

export default teamData;
