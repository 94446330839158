import React from "react";
import faqImg from "../../assets/img/home3/faq_man.png";
import faqBg from "../../assets/img/map_faq.png";
import FaqAccordion from "../FaqAccordion";

function Faq2() {
  return (
    <section
      className="faq-2 bg-contain"
      style={{ backgroundImage: `url(${faqBg})` }}
    >
      <div className="container">
        <div className="row">
          <div className="col-xl-6 col-12 d-none d-xl-block">
            <div className="faq-left-img">
              <div className="man-img">
                <img src={faqImg} alt="man" />
              </div>
            </div>
          </div>

          <div className="col-xl-6 col-12">
            <div className="section-title-3 mb-40">
              <p>faq</p>
              <h1>Get Every Answers</h1>
            </div>

            <div className="faq-content">
              <FaqAccordion
                question="Why it is important to maintain the technical point and quality in construction?"
                answer="Technical and quality work is the foundation of any construction, due to lack of technical; we may have
                                to face horrific challenges in future. Through quality control, we can reduce the cost of construction,
                                promote safety, and give the best results to the customer in the project by orderliness."
              />
              <FaqAccordion
                question="Why do we need professional help and consultation for construction?"
                answer="A construction consultant helps with your construction project. He helps protect you from mistakes that
                could cost you more. Conzarc Consultants can help you create a blueprint that best suit your budget and
                labor costs. To facilitate quality control, consultants can point out problems and propose solutions.
                Construction consultants are always ready to negotiate with other contractors, customers and suppliers
                and help you solve problems. They will support you throughout the project and help you clear
                everything. The Conzarc consultant will oversee the project with you; the consultant also helps you save
                money by negotiating the cost with the supplier."
              />
              <FaqAccordion
                question="What great services does Conzarc provide?"
                answer="Conzarc is well-versed in providing all technical, engineering and construction-related solutions. We also
                provide comprehensive Construction, Preconstruction, Refurbishment, Architectural, Renovation,
                Plumber, Carpenter, Electrical, Home Decoration, Home Care, Home Solutions, Home Products and
                Master Planning Services, and with sustainable construction approach. We have established not only in
                the industry incorporate reliable and dependable technologies, but also state-of-the-art modern
                technology and out-of-the-box ideas."
              />
              <FaqAccordion
                question="Why Choose Conzarc?"
                answer="We provide one-stop solution to all types of construction related problems. We are well-versed in
                solving all types of construction problems with a highly skilled and experienced team.
                We believe in a well organized system and create an efficient team so that customers can enjoy their
                construction without any hassle and doubt. We only use Verified, Qualified &amp; Trained Professionals to
                successfully complete our clients&#39; task"
              />
              <FaqAccordion
                question="How Conzarc helps us?"
                answer="We provide one-stop solution to all kinds of construction related problems. We develop well-organized
                systems and build an efficient team with highly skilled and experienced team to solve all kinds of
                construction problems so that clients can enjoy their construction without any hassle and doubt.
                We use only verified, qualified and trained professionals to successfully complete the tasks for our
                clients. Whether you design, build, we help ensure your projects performance.
                Guide you through every stage of construction, with quality, brand, and budget analysis services for site
                selection, design consultancy, construction testing and construction management, maintenance
                projects, all types of products used in construction."
              />
              <FaqAccordion
                question="How can I get in touch with you?"
                answer="Are you a first-time house owner!
                Are you already worried about the budget, unskilled workers and middlemen?
                But still, want your dream house to flourish.
                Then your search is about to end. We are here to help you.
                With open hands, you can welcome us to make your dream come true! 
                Whether you want a fully construed new house or just want to renovate.
                We hold your hand till the end.
                We are always ready to serve our customers .we are always available primarily through our website and
                social media pages Our technical team is on hand to look after you from 8:00am to 8:00pm is present
                for.
                Give us the opportunity to use our skills to notify you about your next project immediately
                You can also send us on our mail id - admin@conzarc.com or directly call our number 7087835461."
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Faq2;
