import React from "react";
import SinglePost from "./SinglePost";

function BlogDetails() {
  return (
    <section className="blog-wrapper news-wrapper section-padding">
      <div className="container">
        <div className="blog-post-details border-wrap">
          <SinglePost />
        </div>
      </div>
    </section>
  );
}

export default BlogDetails;
