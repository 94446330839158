import React from 'react';

function ServicesThreeCard({ thumb, heading, index, text, heading1 }) {
    return (
        <div className="single-service-box bg-cover" style={{ backgroundImage: `url(${thumb})` }}>
            <div className="service-title">
                <h2>{heading1}</h2>
            </div>
            <div className="hover-flip-content text-left">
               
                <h3>{heading}</h3>
                <p>{text}</p>
            </div>
        </div>
    );
}

export default ServicesThreeCard;
