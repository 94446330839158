import { FaDraftingCompass, FaHardHat, FaPencilRuler } from "react-icons/fa";
import { v4 as uuidv4 } from "uuid";
import thumb1 from "../../assets/img/approch1.jpg";
import thumb2 from "../../assets/img/approch2.jpg";
import thumb3 from "../../assets/img/approch3.jpg";

const approchData = [
  {
    id: uuidv4(),
    thumb: thumb1,
    icon: <FaDraftingCompass />,
    heading: "Vision - Reliable Solutions",
    text: "At Conzarc, our vision is to be a trustworthy one-stop solution for all your construction-related problems. Our highly skilled team provides you with the best professional services, not just as intermediaries. Our vision is to continuously improve project delivery and empower our team to meet our clients business goals. They are with you till you are satisfied with the result.",
    btnText: "Read More",
    pageLink: "/project-details",
  },

  {
    id: uuidv4(),
    thumb: thumb2,
    icon: <FaPencilRuler />,
    heading: "Mission - Happy Customers",
    text: "Our mission is to be a leading construction company in the market, offer top-notch products and services and provide our customers with value for their money. To achieve all our goals and objectives, we adhere to the core of loyalty, brilliance, creativity, open communication as well as integrity, while leading to excellence.",
    btnText: "Read More",
    pageLink: "/project-details",
  },

  {
    id: uuidv4(),
    thumb: thumb3,
    icon: <FaHardHat />,
    heading: "Our Approach - Quality and Innovation",
    text: "We use state-of-the-art modern tools and cutting-edge technology for providing high-quality work, timely delivery, reasonable pricing, and a safe work environment, following the best waste management practices. We follow a sustainable approach and are aligned with legal compliances.",
    btnText: "Read More",
    pageLink: "/project-details",
  },
];

export default approchData;
