import { v4 as uuidv4 } from 'uuid';
import Img1 from '../../assets/img/project/project1.jpg';

const portfolioOneData = [
    {
        id: uuidv4(),
        image: Img1,
        category: 'Industrial',
        client: 'Rosalina D.',
        heading: 'Leverage agile motive frameworks',
        desc: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliqui',
        btnText: 'Case Details',
    },

    {
        id: uuidv4(),
        image: Img1,
        category: 'Industrial',
        client: 'Rosalina D.',
        heading: 'Leverage agile motive frameworks',
        desc: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliqui',
        btnText: 'Case Details',
    },

    {
        id: uuidv4(),
        image: Img1,
        category: 'Industrial',
        client: 'Rosalina D.',
        heading: 'Leverage agile motive frameworks',
        desc: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliqui',
        btnText: 'Case Details',
    },

    {
        id: uuidv4(),
        image: Img1,
        category: 'Industrial',
        client: 'Rosalina D.',
        heading: 'Leverage agile motive frameworks',
        desc: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliqui',
        btnText: 'Case Details',
    },
];

export default portfolioOneData;
