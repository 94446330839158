import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { register } from "../actions/sellerActions";
import SellerSteps from "../components/SellerSteps";
import Loading from "../components/Loading";

export default function SellerRegisterpage(props) {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [rePassword, setRePassword] = useState("");
  const [sphone, setPhone] = useState("");
  const [bankAccountNumber, setbankAccountNumber] = useState(0);
  const [bankName, setbankName] = useState("");
  const [upiNumber, setupiNumber] = useState("");
  const sellerRegister = useSelector((state) => state.sellerRegister);
  const { loading, sellerInfo, error } = sellerRegister;
  const dispatch = useDispatch();
  const history = useHistory();
  useEffect(() => {
    if (sellerInfo) {
      history.push("/seller/signin");
    }
    return () => {};
  }, []);

  const submitHandler = (e) => {
    e.preventDefault();
    dispatch(
      register(
        name,
        email,
        password,
        rePassword,
        sphone,
        bankAccountNumber,
        bankName,
        upiNumber
      )
    );
  };

  return (
    <div>
      <div>
        <SellerSteps step1 />
      </div>
      <center>
        <div className="form_container">
          <form onSubmit={submitHandler}>
            <h1 className="form__title">Create Account</h1>
            <li>
              {loading ? (
                <div>
                  <div style={{ padding: "80px" }}>
                    <Loading />
                  </div>
                </div>
              ) : (
                <div>
                  <div className=" col-12">
                    <label htmlFor="name">Name</label>
                    <br />
                    <input
                      type="name"
                      name="name"
                      id="name"
                      className="form__input sigin-input-register"
                      onChange={(e) => setName(e.target.value)}
                    ></input>
                  </div>
                  <div className=" col-12">
                    <div className="single-personal-info">
                      <label htmlFor="email">Email</label>
                      <br />
                      <input
                        type="email"
                        name="email"
                        id="email"
                        className="form__input sigin-input-register"
                        onChange={(e) => setEmail(e.target.value)}
                      ></input>
                    </div>
                  </div>
                  <div className=" col-12">
                    <div className="single-personal-info">
                      <label htmlFor="sphone">Phone Number</label>
                      <br />
                      <input
                        type="name"
                        name="sphone"
                        id="sphone"
                        className="form__input sigin-input-register"
                        onChange={(e) => setPhone(e.target.value)}
                      ></input>
                    </div>
                  </div>
                  <div className=" col-12">
                    <div className="single-personal-info">
                      <label htmlFor="password">Password</label>
                      <br />
                      <input
                        type="password"
                        id="password"
                        name="password"
                        className="form__input sigin-input-register"
                        onChange={(e) => setPassword(e.target.value)}
                      ></input>
                    </div>
                  </div>
                  <div className=" col-12">
                    <div className="single-personal-info">
                      <label htmlFor="rePassword">Re-Enter Password</label>
                      <br />
                      <input
                        type="password"
                        id="rePassword"
                        name="rePassword"
                        className="form__input sigin-input-register"
                        onChange={(e) => setRePassword(e.target.value)}
                      ></input>
                    </div>
                  </div>
                  <div className=" col-12">
                    <div className="single-personal-info">
                      <label htmlFor="bankAccountNumber">
                        Bank Account Number:
                      </label>
                      <br />
                      <input
                        type="number"
                        id="bankAccountNumber"
                        name="bankAccountNumber"
                        className="form__input sigin-input-register"
                        onChange={(e) => setbankAccountNumber(e.target.value)}
                      ></input>
                    </div>
                  </div>
                  <div className=" col-12">
                    <div className="single-personal-info">
                      <label htmlFor="bankName">Bank Name:</label>
                      <br />
                      <input
                        type="name"
                        id="bankName"
                        name="bankName"
                        className="form__input sigin-input-register"
                        onChange={(e) => setbankName(e.target.value)}
                      ></input>
                    </div>
                  </div>
                  <div className=" col-12">
                    <div className="single-personal-info">
                      <label htmlFor="upiNumber">Upi Id:</label>
                      <br />
                      <input
                        type="name"
                        id="upiNumber"
                        name="upiNumber"
                        className="form__input sigin-input-register"
                        onChange={(e) => setupiNumber(e.target.value)}
                      ></input>
                    </div>
                  </div>

                  <div style={{ textAlign: "center" }}>
                    <button
                      type="submit"
                      className="shop-search-button"
                    >
                      Register
                    </button>
                  </div>
                  <div style={{ textAlign: "center" }}>
                    Already have an account?{" "}
                    <Link to="/seller/signin">Sign-in</Link>
                  </div>
                </div>
              )}
              {error && <div>{error}</div>}
            </li>
          </form>
        </div>
      </center>
    </div>
  );
}
