import React from "react";
import Header3 from "../components/Header3";
import PageBanner from "../components/PageBanner";
import bannerBg from "../assets/img/page-banner.jpg";
import Footer3 from "../components/Footer3";
import ComplaintsContent from "../components/ComplaintsContent";

const Complaints = () => {
  return (
    <>
      <Header3 />
      <PageBanner
        bannerBg={bannerBg}
        heading="Complaints"
        currentPage="Complaints"
      />
      <ComplaintsContent/>
      <Footer3></Footer3>
    </>
  );
};

export default Complaints;