import React from "react";
import aboutTwoData from "./aboutTwoData";

function About2() {
  return (
    <section className="about-wrapper section-padding-less pb-0">
      <div className="container">
        <div className="row align-center">
          <div className="col-lg-4 col-12 d-none d-lg-block">
            <div className="promo-img">
              <img src={aboutTwoData.img} alt="" />
            </div>
          </div>
          <div className="col-lg-8 pl-xl-15 col-16">
            <div className="block-contents">
              <span>About Our Company</span>
              <h3>{aboutTwoData.heading}</h3>
              <p>{aboutTwoData.text}</p>
              <p>
                As a construction hub in India, we are focused on providing
                holistic solutions that include not only construction of
                properties but also interior design, remodelling, maintenance,
                and installations. By integrating eco-friendly and recyclable
                products with modern tools at every turn possible, we are the
                best choice for building sustainable properties.
              </p>
              <h4>A name that depicts our foundation.</h4>
              <p>
                Conzarc stands for ‘con’, meaning construction; z, meaning A to
                Z construction services and products; and arc, meaning
                architecture. It is our mission to provide innovative, reliable
                and affordable property development and construction solutions
                under one roof.
              </p>
              <h4>Our values help us stand apart.</h4>
              <p>
                Focused on providing organised services, Conzarc has developed a
                unique in-house system. We have an exceptional online platform
                that acts as a one-stop solution for all your construction and
                home development needs.
              </p>
              <p>
                We have a team of experienced civil engineers, designers and
                architects and an integrated platform for construction products
                to help you build your dream home. We are on our way to becoming
                a construction hub that will revolutionise the industry.
              </p>
              <h4>One-for-all construction solutions</h4>
              <p>
                Conzarc is well versed in providing all technical, engineering,
                and construction-related solutions. In each project, we
                incorporate not only reliable and trustworthy techniques
                established in the industry but also cutting-edge modern
                technology and out-of-the-box ideas.{" "}
              </p>
              <p>
                Put our skills to use and start a conversation about your next
                project immediately.
              </p>
              <p>
                Call us on Customer care no 7087835461 or send an email
                <strong> admin@conzarc.in</strong>
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default About2;
