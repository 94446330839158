import React from "react";
import { SRLWrapper } from "simple-react-lightbox";
import img1 from "../../assets/img/gallery/5.jpg";
import img2 from "../../assets/img/gallery/6.jpg";

function ConstructionContent() {
  return (
    <>
      <p>
        If you are going to build a house for the first time and are worried
        about the construction budget, unskilled labor and middlemen, then your
        search is about to end. We are here to help you. With open hands, you
        can welcome us to make your dream come true! Whether you want a fully
        construed new house or just want to renovate or repair/ restorations.
      </p>
      <p>
        Conzarc is well versed in providing all technical, engineering, and
        construction-related solutions. In each project, we incorporate not only
        reliable and trustworthy techniques established in the industry but also
        cutting-edge modern technology and out-of-the-box ideas.
      </p>
      <p>
        We develop trustworthy and efficient relationships with our clients,
        artisans and partners, where through a well-organized system; we can
        bring immense benefits to our clients. Waste and resource management,
        efficient and safe energy use, environmental management systems - all
        these are essential to the company and our customers
      </p>
      <p>
        Conzarc is an ISO 9001:2015 certified company, the concept of which
        forms a qualitatively new approach - zero-accident philosophy that
        unites three major elements of the professional work ethic in the
        construction industry: safe and healthy work conditions, labor
        protections, and employee welfare.
      </p>
      <SRLWrapper>
        <div className="row">
          <div className="col-md-6 col-12">
            <a href={img1} className="popup-gallery">
              <img src={img1} alt="" />
            </a>
          </div>
          <div className="col-md-6 col-12">
            <a href={img2} className="popup-gallery">
              <img src={img2} alt="" />
            </a>
          </div>
        </div>
      </SRLWrapper>
    </>
  );
}

export default ConstructionContent;
