export const DELIVERY_SIGNIN_REQUEST = 'DELIVERY_SIGNIN_REQUEST';
export const DELIVERY_SIGNIN_SUCCESS = 'DELIVERY_SIGNIN_SUCCESS';
export const DELIVERY_SIGNIN_FAIL = 'DELIVERY_SIGNIN_FAIL';

export const CURRENT_ORDER_REQUEST = 'CURRENT_ORDER_REQUEST';
export const CURRENT_ORDER_SUCCESS = 'CURRENT_ORDER_SUCCESS';
export const CURRENT_ORDER_FAIL = 'CURRENT_ORDER_FAIL';

export const DETAILS_ORDER_REQUEST = 'DETAILS_ORDER_REQUEST';
export const DETAILS_ORDER_SUCCESS = 'DETAILS_ORDER_SUCCESS';
export const DETAILS_ORDER_FAIL = 'DETAILS_ORDER_FAIL';