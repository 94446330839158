import React from "react";
import { SRLWrapper } from "simple-react-lightbox";
import img1 from "../../assets/img/gallery/5.jpg";
import img2 from "../../assets/img/gallery/6.jpg";

function ConstructionMaterialContent() {
  return (
    <>
      <p>
        At Conzarc we as our construction material supplies are responsible for
        providing excellent construction material to our clients at reasonable
        prices, without any middlemen. We want our customer to get the
        construction related product at one place, reasonable price, good brand,
        and best quality and at the right time for construction, we give more
        profit to our customer by directly contacting the manufacturing company,
        in doing so we try to participate in the upliftment of the manufacturing
        companies of the country and our customers.
      </p>
      <h2>Material quality control</h2>
      <p>
        At Conzarc we work intensively to deliver unique features to our
        customers through online marketing. Specification and quality control of
        construction materials is our prime responsibility. We understand
        customer needs and work hard to meet them. We ensure 100% quality of the
        material as per the client&#39;s requirements before supplying the
        construction material to the end customer.
      </p>
      <h2>Trustworthy Building Material Partner on One Click</h2>
      <p>
        Conzarc provides innovative solutions and efficient services in the
        construction and infrastructure sectors through its proven business
        model. Our Building Materials Division offers a unique business concept.
        Different types of materials required for manufacturing, great brand,
        great company, latest products, latest technology, keep our customer
        ahead by providing timely information through product consultation
      </p>

      <SRLWrapper>
        <div className="row">
          <div className="col-md-6 col-12">
            <a href={img1} className="popup-gallery">
              <img src={img1} alt="" />
            </a>
          </div>
          <div className="col-md-6 col-12">
            <a href={img2} className="popup-gallery">
              <img src={img2} alt="" />
            </a>
          </div>
        </div>
      </SRLWrapper>
    </>
  );
}

export default ConstructionMaterialContent;
