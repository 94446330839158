import React from 'react';
import bannerBg from '../assets/img/service-banner.png';
import Footer3 from '../components/Footer3';
import Header3 from '../components/Header3';
import PageBanner from '../components/PageBanner';
import Process from '../components/Process';
import ProjectFilter1 from '../components/ProjectFilter';
import Services2 from '../components/Services2';
import Services1 from '../components/Services1';


function Services() {
    return (
        <>
            <Header3 />
            <PageBanner bannerBg={bannerBg} currentPage="Services" heading="" />
            <Services2 />
            <Services1/>
            <Process />
            <ProjectFilter1/>
            <Footer3 />
        </>
    );
}

export default Services;
