import React from "react";
import DetailsCarousel from "./DetailsCarousel";
import DetailsContent from "./DetailsContent";

function PaintingDetails() {
  return (
    <div className="service-details-content-wrapper pl-0 pl-md-4">
      <DetailsCarousel />
      <DetailsContent />
      <div className="faq-content pl-0 pl-md-4">
        <h2>Why Choose Us?</h2>
        <div className="service-details-plumbing">Quality Assurance</div>
        <div className="service-details-plumbing">
          We Only Hire Trained and Skilled Person to Maintain the Quality
        </div>
        <div className="service-details-plumbing">Competitive cost</div>
        <div className="service-details-plumbing">
          We believe in doing great things with the right values
        </div>
        <div className="service-details-plumbing">
          Experience and Punctuality
        </div>
        <div className="service-details-plumbing">
          IMany years of experience in residential properties and commercial
          buildings keep us committed to getting the job done on time
        </div>
        <div className="service-details-plumbing">Guarantee</div>
        <div className="service-details-plumbing">
          All our works are covered under a 12 months guarantee.
        </div>
      </div>
    </div>
  );
}

export default PaintingDetails;
