import React from "react";
import teamData from "./teamData";
import TeamItem from "./TeamItem";

function TeamMembers() {
  return (
    <section className="team-section section-padding-less">
      <div className="container">
        <div className="row">
          <div className="col-12 col-lg-12 text-center">
          
            <div className="section-title-2">
            <p className="oue-experise">Our Expertise</p>
              <h1>Our Expert Mates</h1>
              {/* <span>Experts</span> */}
            </div>
          </div>
        </div>
        <br />
        <div className="row mtm-30">
          {teamData.map((data) => (
            <TeamItem
              key={data.id}
              img={data.img}
              name={data.name}
              position={data.position}
              fbLink={data.fbLink}
              twitterLink={data.twitterLink}
              behanceLink={data.behanceLink}
              youtubeLink={data.youtubeLink}
            />
          ))}
        </div>
      </div>
    </section>
  );
}

export default TeamMembers;
