import React from "react";
import Appointment from "../components/Appointment";
import Faq2 from "../components/Faq2";
import Footer3 from "../components/Footer3";
import Header3 from "../components/Header3";
import Hero3 from "../components/Hero3";
import Portfolio2 from "../components/Portfolio2";
import Process2 from "../components/Process2";
import Services3 from "../components/Services3";
import Services1 from "../components/Services1";
import Sponsors2 from "../components/Sponsors2";
import VideoFeatrued from "../components/VideoFeatured";
// import Analytics from '../components/Analytics';
import Cta3 from '../components/Cta3';
import Cta2 from '../components/Cta2';
import Cta4 from "../components/cta4";
function Home3() {
  return (
    <>
      <Header3 />
      <Hero3 />
      <Process2 />
      <Services3 />
      <VideoFeatrued />
      <Faq2 />
      <br/>
      <br/>
      <Services1 />
      <Cta4/>
      <Appointment />
      <Cta2/>
      <Portfolio2 />
      <Sponsors2 />
      <Cta3/>
      <Footer3 />
    </>
  );
}

export default Home3;
