import React from "react";
import Header3 from "../components/Header3";
import PageBanner from "../components/PageBanner";
import bannerBg from "../assets/img/page-banner.jpg";
import Footer3 from "../components/Footer3";
import { ReturnsComp } from "../components/ReturnsComp";

export const ReturnPolicy = () => {
  return (
    <>
      <Header3 />
      <PageBanner
        bannerBg={bannerBg}
        heading="Refund And Cancellation Policy"
        currentPage="Refund"
      />
      <ReturnsComp />
      <Footer3></Footer3>
    </>
  );
};
