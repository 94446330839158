import React from "react";

export const ReturnsComp = () => {
  return (
    <div className="privacy-policy">
      <h4>CANCELLATION POLICY</h4>
      <div>
        Conzarc reserves the right to update/change the policies anytime without
        prior information, based on internal or external requirements. To make
        sure that you are aware of any changes, kindly review the policy
        periodically. If you do not agree to the policy, please do not use the
        Conzarc website or mobile application. Please read our policy carefully
        to get a clear understanding of cancellation procedures either by
        customer or by service provider and applicable cancellation charges.
      </div>
      <div>CANCELLATION BY CUSTOMER</div>

      <div>
        1. Customer is allowed to cancel/reschedule the requests anytime through
        Conzarc platforms (Website) or by talking to our customer care
        executive.
      </div>
      <div>
        2. Customer is allowed to cancel/reschedule the job request 30 minute
        before the scheduled job time (in case of Schedule Job) and within 10
        minutes of the job request for on-time job delivery. Customer will be
        charged one unit price as cancellation fee or inconvenience fee in such
        scenarios.
      </div>
      <div>
        3. Customer will be charged one unit fee and all applicable charges if
        he cancels/reschedules the job request on arrival of service provider at
        customer premise.
      </div>
      <div>
        4. Continuous cancellations/reschedule of job request or any malicious
        activities may attract suspension of service by Conzarc and further
        termination of agreement with customer.
      </div>
      <div>
        5. Customer will not be charged for cancellation if the service provider
        is running late by more than 30 minutes from the scheduled time. It is
        appreciated to consider the geographical factors such as traffic
        conditions, accessibility and climate conditions before cancelling the
        request.
      </div>
      <div>
        6. Due to any unforeseen circumstances or internal reasons, Conzarc
        reserves the right to cancel any job request at any point of time with
        communication to customer, and will not be liable to anyone for the
        same.
      </div>
      <div>
        7. Customer is not allowed to cancel/reschedule more than three job
        requests a day. He/she may be suspended from service for any such
        activities.
      </div>
      <div>CANCELLATION BY SERVICE PROVIDER</div>
      <div>
        As per Conzarc Policy, Job cancellation is deny of service by service
        provider after accepting the job request initiated by customer through
        Conzarc platform. Service provider is agreed to accept the Conzarc terms
        and conditions clauses of agreement during the on-boarding process. Any
        violation to the agreement may attract penalty or suspension of service
        and further termination of agreement.
      </div>
      <div>
        1. Service Provider is allowed to cancel maximum 3 requests a day. More
        than 3 cancellations may attract penalty for inconvenience to customer
        as per the agreement with Conzarc.
      </div>
      <div>
        2. Service Provider is allowed to cancel a job before two hours from the
        scheduled time for scheduled job request; job cancellation is not
        permitted for on-time job request.
      </div>
      <div>
        3. Cancellation of job requests or any misbehavior to customers may
        attract reduction in ratings and penalties. These activities may force
        termination of agreement with service provider.
      </div>
      <div>
        4. Service provider needs to provide the reason for cancelling job while
        reverting to Conzarc. These reasons will be saved in service provider’s
        record for performance analysis.
      </div>
      <div>
        5. Service provider is allowed cancel the job on reschedule of job
        request by customer, rules applicable as per Conzarc agreement.
      </div>
      <h4>REFUND POLICY</h4>
      <div>Customer is permitted to claim refund from Conzarc for:</div>
      <div>1. False Service</div>
      <div>2. Incomplete job</div>
      <div>3. Misbehavior from service provider</div>
      <div>
        Customer can send a mail to admin@conzarc.com within 7 days from the day
        of job delivery with subject line “Job Reference #- Complaint Category”.
        Conzarc team will register the complaint and share the complaint number
        within 24 hours via mail/ text message. Customer will receive the final
        status of the claim within 7 working days via mail. If the refund
        request is approved by Conzarc grievance redressed officer, refund
        amount will be credited to customer’s wallet within 48 hours (excluding
        holidays). Conzarc reserves the right to approve or decline a refund
        request; refund claim will be processed by gathering information about
        job delivery from service provider. The status of the claim will be
        shared along with reason for approval/decline with customer via
        registered mail id.
      </div>
      <div>
        Note: Refund policy is applicable only to wallet/online payments.
      </div>
    </div>
  );
};
