import { v4 as uuidv4 } from 'uuid';
import thumb1 from '../../assets/img/home3/service1.jpg';
import thumb2 from '../../assets/img/home3/service2.jpg';
import thumb3 from '../../assets/img/home3/service3.jpg';

const servicesThreeData = [
    {
        id: uuidv4(),
        thumbnail: thumb1,
        index: '01',
        heading1:"ISO 9001:2015 Certified Company",
        heading: 'Quality Management System',
        text: 'CONZARC follows quality management system procedures for monitoring management system in line with international standard. Management is keen to implement these processes and seeks accreditation and certification from International Standards Organizations.',
    },

    {
        id: uuidv4(),
        thumbnail: thumb2,
        index: '02',
        heading1:"ISO 45001: 2018 Certified Company",
        heading: 'Health, Safety and Environment',
        text: 'We are focused on protecting our workforce and protecting the communities where we operate. We provide complete and extensive training for our people, and implement our processes fully in line with international health, safety and environment (HSE) standards.',
    },
    {
        id: uuidv4(),
        thumbnail: thumb1,
        index: '03',
        heading1:"ISO 9001:2015 Certified Company",
        heading: 'Work in More Than 5k',
        text: '',
    },

    {
        id: uuidv4(),
        thumbnail: thumb3,
        index: '04',
        heading1:"ISO 9001:2015 Certified Company",
        heading: 'Our History',
        text: '',
    },
];

export default servicesThreeData;
