import React from "react";
import CarpentaryCarousel from "./DetailsCarousel";
import CarpentaryContent from "./DetailsContent";

function CarpentaryDetails() {
  return (
    <div className="service-details-content-wrapper pl-0 pl-md-4">
      <CarpentaryCarousel />
      <CarpentaryContent />
      <div className="faq-content pl-0 pl-md-4">
        <h2>SERVICE LIST</h2>
        <div className="service-details-plumbing">
          Wardrobe and cabinet building
        </div>
        <div className="service-details-plumbing">
          bed sofa and other furniture repair and building minor furniture
          repair carpentry work furniture painting and polishing furniture
        </div>
        <div className="service-details-plumbing">Heavy wooden work</div>
        <div className="service-details-plumbing">
          wardrobe design and building work
        </div>
        <div className="service-details-plumbing">housing carpentry work</div>
        <div className="service-details-plumbing">
          furniture assembly services
        </div>
        <div className="service-details-plumbing">would furniture repair</div>
        <div className="service-details-plumbing">
          maintenance of wooden products
        </div>
        <div className="service-details-plumbing">Shuttering work</div>
        <div className="service-details-plumbing">
          Remodel cabins &amp; other buildings adding kitchens, baths
        </div>
        <div className="service-details-plumbing">
          Finish Carpentry/Doors and hardware
        </div>
        <div className="service-details-plumbing">
          Interior/exterior trim work, hanging doors/drilling and setting
          hardware.
        </div>
        <div className="service-details-plumbing">
          Frame and insulate walls-Completed demo work-install cabinets,
          flooring, and new trim-Made and finish trim-Constructed stairway
        </div>
        <div>
          Call us or book a Professional carpenters for refurbishment, repair
          and maintenance of your furniture repairs, if you have higher
          expectations with your carpenters but have not found the appropriate
          one. Our carpenter (Woodmen) gives you the best end services.
        </div>
      </div>
    </div>
  );
}

export default CarpentaryDetails;
