import React from "react";
import Header3 from "../components/Header3";
import PageBanner from "../components/PageBanner";
import bannerBg from "../assets/img/page-banner.jpg";
import PrivacyContent from "../components/PrivacyContent";
import Footer3 from "../components/Footer3";

const Privacy = () => {
  return (
    <>
      <Header3 />
      <PageBanner
        bannerBg={bannerBg}
        heading="Privacy Policy"
        currentPage="Privacy"
      />
      <PrivacyContent></PrivacyContent>
      <Footer3></Footer3>
    </>
  );
};

export default Privacy;
