import React from "react";
import { SRLWrapper } from "simple-react-lightbox";
import img1 from "../../assets/img/gallery/5.jpg";
import img2 from "../../assets/img/gallery/6.jpg";

function GardenDetailsContent() {
  return (
    <>
      <center>
        <h2>WHERE THERE IS GREENERY THERE IS HAPPINESS</h2>
      </center>

      <p>
        We provide experienced and skilled gardeners to take care of your garden
        in a professional manner and maintain its beauty, who can advise you
        about the nursery suitable for every season and keep your garden green
        and beautiful.
      </p>
      <h2>Key Benefits our Service</h2>
      <p>
        <div>
          We help you better in choosing such trees, plants, ornamental flowers,
          botanical herbs that can contribute to the decoration as well as
          keeping your environment pure, developed by the best modern nurseries
        </div>
        <br />
        <div>
          We provide reliable service to our clients through all round
          professional and trending gardeners to make the environment better by
          taking care of their garden interest.
        </div>
        <br />
        <div>
          We will always try to make your garden always green with our best
          suggestions.
        </div>
      </p>
      <SRLWrapper>
        <div className="row">
          <div className="col-md-6 col-12">
            <a href={img1} className="popup-gallery">
              <img src={img1} alt="" />
            </a>
          </div>
          <div className="col-md-6 col-12">
            <a href={img2} className="popup-gallery">
              <img src={img2} alt="" />
            </a>
          </div>
        </div>
      </SRLWrapper>

      <h2>Garden Maintenance request</h2>
      <p>
        All aspects of gardening are undertaken- We operate 7 days a week and
        offer on-time appointments.
      </p>
    </>
  );
}

export default GardenDetailsContent;
