import React from "react";
import ConstructionMaterialCarousel from "./DetailsCarousel";
import ConstructionMaterialContent from "./DetailsContent";

function ConstructionMaterialDetails() {
  return (
    <div className="service-details-content-wrapper pl-0 pl-md-4">
      <ConstructionMaterialCarousel />
      <ConstructionMaterialContent />
      <div className="faq-content pl-0 pl-md-4">
        <div className="service-details-plumbing">Best quality Products</div>
        <div className="service-details-plumbing">Timely deliveries</div>
        <div className="service-details-plumbing">Reputed brands</div>
        <div className="service-details-plumbing">Professional services</div>
      </div>
    </div>
  );
}

export default ConstructionMaterialDetails;
