import React from "react";
import Footer3 from "../components/Footer3";
import Header3 from "../components/Header3";
import PageBanner from "../components/PageBanner";
import bannerBg from "../assets/img/page-banner.jpg";
import QualityImage from "../assets/img/Health-Safety-Policy_1.jpg";
import QualityImage2 from "../assets/img/Health-Safety-Policy_2.jpg";

function SafetyPolicy() {
  return (
    <>
      <Header3></Header3>
      <PageBanner
        bannerBg={bannerBg}
        heading="Safety Policy"
        currentPage="Safety Policy"
      />
      <center>
        <img src={QualityImage} alt=""/>
        <img src={QualityImage2} alt=""/>
      </center>
      <Footer3 />
    </>
  );
}

export default SafetyPolicy;