import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { listShops } from "../actions/shopActions";
import Navstuff from "../components/Navstuff.js";
import HomeShop from "../components/home_shop";
import Loading from "../components/Loading";
import PageBanner from "../../components/PageBanner";
export default function Homepage(props) {
  const shopList = useSelector((state) => state.shopList);
  const [searchKeyword, setSearchKeyword] = useState("");
  const { shops, loading, error } = shopList;
  const dispatch = useDispatch();
  // const redirect = props.location.search
  //   ? props.location.search.split('=')[1]
  //   : '/';

  useEffect(() => {
    dispatch(listShops(searchKeyword));
    return () => {
      //
    };
  }, []);

  const submitHandler = (e) => {
    e.preventDefault();
    dispatch(listShops(searchKeyword));
  };
  const ClearCartHandle = () => {
    // if (redirect === 'true') {
    //  // dispatch(removeAllFromCart());
    //   props.history.push('/');
    // }
  };
  ClearCartHandle();
  return (
    <div>
      <Navstuff />

      <form onSubmit={submitHandler}>
        <center>
          <div className="searchbox ">
            <input
              className="searchbox-input"
              placeholder="Search Shops"
              onChange={(e) => setSearchKeyword(e.target.value)}
            />
            <button
              className="shop-search-button"
              type="submit"
            >
              Search
            </button>
          </div>
        </center>
      </form>
      <PageBanner
        bannerBg="https://images.unsplash.com/photo-1589939705384-5185137a7f0f?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80"
        heading="Shops"
        currentPage="Shop"
      />
      {loading ? (
        <Loading />
      ) : error ? (
        { error }
      ) : (
        <div className="content-wrapper">
          {shops.map((shop) => (
            <Link to={`/shop/${shop._id}`}>
              <HomeShop shop={shop} />
            </Link>
          ))}
        </div>
      )}
    </div>
  );
}
