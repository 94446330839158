import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
// import {
//   addToCart,
//   removeFromCart,
//   removeAllFromCart,
// } from '../actions/cartActions';
import { createOrder } from "../actions/orderActions";
import DeliveryImage from "../../assets/img/delivery.png";
import { Link } from "react-router-dom";

function loadRazyorPay() {
  return new Promise((resolve) => {
    const script = document.createElement("script");
    script.src = "https://checkout.razorpay.com/v1/checkout.js";

    script.onload = () => {
      resolve(true);
    };
    script.onerror = () => {
      resolve(false);
    };
    document.body.appendChild(script);
  });
}

export default function Placeorder(props) {
  const dispatch = useDispatch();
  const cart = useSelector((state) => state.cart);
  const { cartItems, shipping } = cart;

  const itemPrice = cartItems.reduce(
    (total, product) => total + product.pprice * product.qty,
    0
  );

  const placeOrderHandler = () => {
    // create an order
    handlePayment();
  };

  // const handleOpen = () => {
  //   setOpen(true);
  // };

  // const clearCartHandler = () => {
  //   dispatch(removeAllFromCart());
  // };

  const [open, setOpen] = React.useState(false);

  //! payment
  const userSignin = useSelector((state) => state.userSignin);
  const { userInfo } = userSignin;

  console.log(userInfo);
  async function handlePayment() {
    const res = await loadRazyorPay();
    if (!res) {
      alert("Razorpay sdk failed");
      return;
    }
    var options = {
      key: "rzp_test_UeLgIlo31nrx2i",
      amount: itemPrice * 100,
      currency: "INR",
      name: "Conzarc",
      description: "Conzarc-Order Transaction",
      image:
      "https://user-images.githubusercontent.com/88541819/183144867-35d3d892-422a-4c1a-bf88-651ec2d54f5e.png",
      handler: function (response) {
        if (
          response.razorpay_payment_id !== undefined &&
          response.razorpay_payment_id !== null
        ) {
          dispatch(
            createOrder({
              cartItems: cartItems,
              shipping,
              itemPrice,
            })
          );

          //opens the model after creating order
          setOpen(true);
        }
        // alert("payment id"); //response.razorpay_payment_id //! check this
        // alert("order id "); //response.razorpay_order_id,
        // alert("razorpay_signature"); //response.razorpay_signature
      },
      prefill: {
        name: userInfo.name,
        email: userInfo.email,
      },
      notes: {
        address: "Razorpay Corporate Office",
      },
      theme: {
        color: "#3399cc",
      },
    };
    const paymentObject = new window.Razorpay(options);
    paymentObject.open();
  }

  return (
    <div className="placeorder-container">
      {!open && (
        <div className="place_order_container">
          <div className="placeorder">
            <div className="placeorder-info">
              <div className="placeorder-title-container">
                <h6 className="place-order-title">Delivery Address</h6>
                <div style={{ marginTop: "1rem" }}>
                  <div> 
                  address1: {cart.shipping.address1}
                    </div>
                    <div> 
                    address2:{" "}
                  {cart.shipping.address2} - {cart.shipping.area}, city:{" "}
                  {cart.shipping.city}
                    </div>
                </div>
              </div>

              <br />
              <div>
                <div className="cart-list-container">
                  
                    <h3>Shopping Cart</h3>
                    <div></div>
                    <br />
                  
                  {cartItems.length === 0 ? (
                    <div>Cart is empty</div>
                  ) : (
                    cartItems.map((item) => (
                      <div className="shipping_cart_product_container">
                        <div className="cart-image" style={{ padding: 10 }}>
                          <img
                            src={item.image}
                            alt="product"
                            height={100}
                            width={100}
                          />
                        </div>
                        <div className="cart-name">
                          <div>
                            <Link to={"/product/" + item.product}>
                              {item.pname}
                            </Link>
                          </div>
                          <div>Qty: {item.qty}</div>
                          
                        </div>
                        <div className="cart-price">₹{item.pprice}</div>
                      </div>
                    ))
                  )}
                </div>
              </div>
            </div>
            <br />
            <div className="placeorder-action">
              <ul>
                <li>
                  <h3 className="form__title">Order Summary</h3>
                </li>
                <li>
                  <div style={{ flexDirection: "row" }}>
                    <div>
                      <h5 className="form__title">
                        Total Price Rs.{itemPrice}
                      </h5>
                    </div>
                  </div>
                </li>
                <br />
                <li>
                  <button
                    className="shop-search-button"
                    onClick={placeOrderHandler}
                  >
                    Place Order
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </div>
      )}

      {open && (
        <div
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={open}
          closeAfterTransition
          BackdropProps={{
            timeout: 500,
          }}
        >
          <div in={open}>
           
            <center className="center-description">
            <img src={DeliveryImage} alt="delivery"  />
              <h2 id="transition-modal-title">Order Successful</h2>
              <p id="transition-modal-description">
                Thank you for choosing us your order will be delivered shortly
              </p>
              <button
                className="shop-search-button"
                onClick={(e) => {
                  e.preventDefault();
                  window.location.href = "/shop?orderplace=true";
                }}
              >
                Back to Homepage
              </button>
            </center>
          </div>
        </div>
      )}
    </div>
  );
}
